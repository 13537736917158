import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import Vant from "vant";
import store from "./store";
import "vant/lib/index.css";
import "./css/overall.css";
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
import echarts from "echarts";
import Vue2OrgTree from "vue2-org-tree";
import "vue2-org-tree/dist/style.css";
import Vue2TouchEvents from "vue2-touch-events";
import moment from "moment";
import htmlToPdf from "@/utils/htmlToPdf";

import ws from "@/unitls/ws";

Vue.prototype.$ws = ws;

Vue.use(Vue2TouchEvents);
Vue.use(Vue2OrgTree);
Vue.prototype.$echarts = echarts;
Vue.prototype.$moment = moment;
Vue.use(ElementUI);
Vue.use(Vant);
Vue.config.productionTip = false;
Vue.use(htmlToPdf);

const url = window.location.href;
// console.log(url, "网页地址");
let urlStr = url.split("v=")[1];
localStorage.setItem("version", urlStr);

new Vue({
  store,
  router,
  render: (h) => h(App),
}).$mount("#app");
