<template>
  <div
    class="dataChart"
    ref="dataChart"
    v-loading="loading"
    style="display: flex"
  >
    <div ref="chart" style="width: 3rem; padding-top: 8px">
      <div class="bloodRate1" style="background: rgb(255, 168, 0)"></div>
      <div class="bloodRate2" style="background: rgb(255, 212, 38)"></div>
      <div class="bloodRate3" style="background: rgb(101, 209, 114)"></div>
      <div class="bloodRate4" style="background: rgb(255, 113, 113)"></div>
      <div class="bloodRate5" style="background: rgb(238, 37, 37)"></div>
    </div>
    <div
      style="
        color: #333;
        width: 6rem;
        margin-left: 0.5rem;
        text-align: left;
        height: 196px;
        float: left;
        font-size: 0.8rem;
        padding-top: 8px;
      "
    >
      <div class="bloodRate1">
        {{ bloodRate[0] == "NaN" ? "-" : bloodRate[0] }}% 非常高
      </div>
      <div class="bloodRate2" style="margin-left: -120%">
        {{ bloodRate[1] == "NaN" ? "-" : bloodRate[1] }}% 高
      </div>
      <div class="bloodRate3">
        {{ bloodRate[2] == "NaN" ? "-" : bloodRate[2] }}% 正常
      </div>
      <div class="bloodRate4" style="margin-left: -120%">
        {{ bloodRate[3] == "NaN" ? "-" : bloodRate[3] }}% 低
      </div>
      <div class="bloodRate5">
        {{ bloodRate[4] == "NaN" ? "-" : bloodRate[4] }}% 非常低
      </div>
    </div>
  </div>
</template>

<script>
import { getRate, formatData } from "@/pages/sugerData/getSugerData";
import { getSugerbyId } from "@/api/userServices.js";
export default {
  name: "DataChart",
  data() {
    return {
      height: "",
      isHaveData: true,
      bloodHeight: [],
      bloodRate: [],
      classList: [
        "bloodRate1",
        "bloodRate2",
        "bloodRate3",
        "bloodRate4",
        "bloodRate5",
      ],
      loading: false,
    };
  },
  created() {
    this.getSugarData();
  },
  mounted() {
    this.height = this.$refs.dataChart.offsetHeight * 0.9;
    this.$refs.chart.style.height = this.height;
  },
  methods: {
    getSugarData() {
      this.loading = true;
      const now = this.$moment().format("YYYY-MM-DD HH:mm:ss");
      const params = {
        id: JSON.parse(localStorage.getItem("appuser")).id,
        end: now,
        start: this.$moment(now)
          .subtract(24, "hours")
          .format("YYYY-MM-DD HH:mm:ss"),
      };
      getSugerbyId(params).then((res) => {
        if (!res.data.data.length) {
          this.isHaveData = false;
          this.bloodHeight = ["20", "20", "20", "20", "20"];
          this.bloodRate = ["0", "0", "0", "0", "0"];
        } else {
          this.SugerData = formatData(res);
          this.bloodRate[0] = getRate(this.SugerData, 15, 100);
          this.bloodRate[1] = getRate(this.SugerData, 10, 15);
          this.bloodRate[2] = getRate(this.SugerData, 3.9, 10);
          this.bloodRate[3] = getRate(this.SugerData, 2, 3.9);
          this.bloodRate[4] = getRate(this.SugerData, 0, 2);
          this.bloodHeight = this.bloodRate;
        }
        this.setBloodRate();
        this.loading = false;
      });
    },
    setBloodRate() {
      for (let index = 0; index < this.classList.length; index++) {
        const element = this.classList[index];
        document.getElementsByClassName(element)[0].style.height =
          (this.bloodHeight[index] * this.height) / 100 + "px";
        document.getElementsByClassName(element)[1].style.height =
          (this.bloodHeight[index] * this.height) / 100 + "px";
        document.getElementsByClassName(element)[1].style["line-height"] =
          (this.bloodHeight[index] * this.height) / 100 + "px";
      }
    },
  },
};
</script>

<style scoped>
[class^="bloodRate"] {
  min-height: 3px;
  margin-top: 1px;
  border-radius: 2px;
}
</style>
