<template>
  <div class="common-box">
    <!-- 标题 -->
    <div class="title">每日血糖曲线</div>
    <!-- 散点图 -->
    <div id="todaysugar" v-loading="dayLoading" style="width: 100%; height: 24rem"></div>
  </div>
</template>
<script>
import { Line } from "@antv/g2plot";
import { getBGbyToken, getSugerbyId } from "@/api/userServices";
export default {
  props: {
    selectedDate: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      dayLoading: false,
      scatterPlot: null,
      chartData: [],
      // 时间段选择
      time: 3,
      isToday: true,
    };
  },
  created() {
    setTimeout(() => {
      this.getChart();
    }, 1000);

    if (this.$route.query.id) {
      this.getDateById();
    } else {
      this.getDateArray();
    }
  },
  methods: {
    getChart() {
      let data = this.chartData;
      this.scatterPlot = new Line("todaysugar", {
        appendPadding: [30, 5, 10, 5],
        data,
        xField: "create_time",
        yField: "v",
        // seriesField: "tooltip_time",
        xAxis: {
          type: "time",
          max: this.$moment(this.maxTime).valueOf(),
          min: this.$moment(this.minTime).valueOf(),
          mask: "HH:mm", // 显示格式为小时:分钟
          tickMethod: (scale) => {
            let { min, max, tickCount } = scale;
            const time = this.maxTime.substring(11, 13);
            let tickInterval = "";
            if (this.isToday) {
              if (time <= 3) {
                tickInterval = 1000 * 60 * 30;
              } else if (time <= 7) {
                tickInterval = 1000 * 60 * 60;
              } else if (time <= 18) {
                const next = this.$moment(this.maxTime)
                  .add(1, "hours")
                  .format("YYYY-MM-DD HH:00:00");
                max = time % 2 ? new Date(next).valueOf() : max;
                tickInterval = 1000 * 60 * 60 * 2;
              } else if (time <= 24) {
                const last = this.$moment()
                  .add(1, "days")
                  .format("YYYY-MM-DD 00:00:00")
                  .valueOf();
                max = new Date(last).valueOf();
                tickInterval = 1000 * 60 * 60 * 3;
              }
            } else {
              tickInterval = 1000 * 60 * 60 * 3;
            }
            const ticks = [];
            for (let i = min; i <= max; i += tickInterval) {
              ticks.push(i);
            }
            return ticks;
          },
        },
        yAxis: {
          nice: true,
          min: 0,
          max: 20,
          grid: {
            line: {
              style: {
                lineWidth: 2,
                lineDash: [4, 5],
              },
            },
          },
        },
        legend: false,
        tooltip: {
          showTitle: false,
          customContent: (title, data) => {
            if (title !== null) {
              return `<div style="padding:4px;">
                      <div style="font-size:16px;font-weight: 700;margin-bottom:4px;">血糖: ${data[0].data.v}</div>
                      <div> ${data[0].data.tooltip_time}</div>
               </div>`;
            }
          },
        },
        annotations: [
          {
            type: "region",
            start: ["min", this.$route.query.targetLow?this.$route.query.targetLow:localStorage.getItem('targetLow')],
            end: ["max", this.$route.query.targetHigh?this.$route.query.targetHigh:localStorage.getItem('targetHigh')],
            style: {
              stroke: "#edf0f2",
              lineDash: [2, 2],
            },
          },
          {
            type: "regionFilter",
            start: ["min", this.$route.query.targetHigh?this.$route.query.targetHigh:localStorage.getItem('targetHigh')],
            end: ["max", 20],
            color: "#F4664A",
          },
          {
            type: "text",
            position: ["min", this.$route.query.targetHigh?this.$route.query.targetHigh:localStorage.getItem('targetHigh')],
            content: this.$route.query.targetHigh?this.$route.query.targetHigh:localStorage.getItem('targetHigh'),
            style: {
              fill: "#F4664A",
            },
          },
          {
            type: "regionFilter",
            start: ["min", 0],
            end: ["max", this.$route.query.targetLow?this.$route.query.targetLow:localStorage.getItem('targetLow')],
            color: "#ffaa29",
          },
          {
            type: "text",
            position: ["min", this.$route.query.targetLow?this.$route.query.targetLow:localStorage.getItem('targetLow')],
            content: this.$route.query.targetLow?this.$route.query.targetLow:localStorage.getItem('targetLow'),
            style: {
              fill: "#ffaa29",
            },
          },
        ],
      });
      this.scatterPlot.render();
    },

    getDateArray() {
      this.dayLoading = true
      this.chartData = [];
      const params = {
        end: this.selectedDate + " 23:59:59",
        start: this.selectedDate + " 00:00:00",
        // end: this.maxTime,
        // start: this.minTime,
      };
      getBGbyToken(params).then((res) => {
        const today = this.$moment().format("YYYY-MM-DD");
        if (res.data.data && res.data.data.length) {
          res.data.data.forEach((item) => {
            this.chartData.push({
              create_time: today + " " + item.create_time.substring(11, 16),
              v: item.v,
              tooltip_time: item.create_time.substring(5, 16),
            });
          });
          setTimeout(() => {
            this.dayLoading = false
          }, 2000);
        } else {
          setTimeout(() => {
            this.dayLoading = false
          }, 2000);
        }
      });
    },

    getDateById() {
      this.chartData = [];
      const params = {
        id: this.$route.query.id,
        end: this.selectedDate + " 23:59:59",
        start: this.selectedDate + " 00:00:00",
        // end: this.maxTime,
        // start: this.minTime,
      };
      getSugerbyId(params).then((res) => {
        const today = this.$moment().format("YYYY-MM-DD");
        if (res.data.data && res.data.data.length) {
          res.data.data.forEach((item) => {
            this.chartData.push({
              create_time: today + " " + item.create_time.substring(11, 16),
              v: item.v,
              tooltip_time: item.create_time.substring(5, 16),
            });
          });
        }
      });
    },
  },
  computed: {
    minTime() {
      return this.$moment().format("YYYY-MM-DD 00:00:00");
    },
    maxTime() {
      const max = this.isToday
        ? this.$moment().add(1, "hours").format("YYYY-MM-DD HH:00:00")
        : this.$moment().add(1, "days").format("YYYY-MM-DD 00:00:00");
      return max;
    },
  },
  watch: {
    selectedDate: {
      handler: function (newVal, oldVal) {
        this.$nextTick(() => {
          this.isToday = newVal === this.$moment().format("YYYY-MM-DD");
          if (this.$route.query.id) {
            this.getDateById();
          } else {
            this.getDateArray();
          }
          setTimeout(() => {
            this.scatterPlot.destroy();
            this.getChart();
          }, 1500);
        });
      },
    },
  },
};
</script>
<style scoped>
.common-box {
  width: 96%;
  margin: 0.6rem;
  border-radius: 1.2rem;
  background: rgb(255, 255, 255);
  box-shadow: 2px 2px 2px 0px rgba(6, 14, 33, 0.16);
  position: relative;
}
.title {
  height: 3.4rem;
  font-size: 1.2rem;
  font-weight: 700;
  text-align: start;
  line-height: 3.4rem;
  /* border-bottom: 1px solid #ccc; */
  padding-left: 1rem;
}
.van-radio-group--horizontal {
  justify-content: center;
}
.chartCan {
  width: 100%;
  background: #d3eeff;
  position: absolute;
  border-top: 2px dotted #c1b8b8;
  border-bottom: 2px dotted #c1b8b8;
  height: 4.5rem;
  bottom: 9.4rem;
}
.bottom-num {
  position: absolute;
  right: 5px;
  bottom: -16px;
  color: red;
}
.top-num {
  position: absolute;
  right: 5px;
  top: -16px;
  color: orange;
}
.unit {
  position: absolute;
  left: 5px;
  margin-top: 5px;
}
.van-radio__label {
  color: #7accff;
}
</style>
