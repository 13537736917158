import { render, staticRenderFns } from "./detailChart.vue?vue&type=template&id=5d9bbfce&scoped=true"
import script from "./detailChart.vue?vue&type=script&lang=js"
export * from "./detailChart.vue?vue&type=script&lang=js"
import style0 from "./detailChart.vue?vue&type=style&index=0&id=5d9bbfce&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5d9bbfce",
  null
  
)

export default component.exports