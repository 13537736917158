import { render, staticRenderFns } from "./pointChart.vue?vue&type=template&id=2d971d78&scoped=true"
import script from "./pointChart.vue?vue&type=script&lang=js"
export * from "./pointChart.vue?vue&type=script&lang=js"
import style0 from "./pointChart.vue?vue&type=style&index=0&id=2d971d78&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2d971d78",
  null
  
)

export default component.exports