import api from "./services.js";
import { baseUrl } from "./host.js";
const env = baseUrl.env;
export const getAll = (param) =>
  api({
    url: param.url,
    method: param.method,
    params: param.params,
  });
const host = baseUrl[env].host;
const port = baseUrl[env].port;
//  获取验证码
export const getCode = (phone) =>
  api({
    url: host + port.user + "auth/getcode",
    method: "post",
    params: {
      phone: phone,
    },
  });
// 登录
export const login = (params) =>
  api({
    url: host + port.user + "user/loop/login",
    method: "post",
    data: params,
  });
// 注册
export const register = (params) =>
  api({
    url: host + port.user + "auth/register",
    method: "post",
    data: params,
  });
// 获取事件列表
export const loginOUT = (params) =>
  api({
    url: host + port.user + "login/out",
    method: "get",
    params: params,
  });
// 修改用户信息
export const update = (params) =>
  api({
    url: host + port.user + "auth/update",
    method: "post",
    data: params,
  });
// 通过token修改用户信息 updatebutoken
export const updatebutoken = (params) =>
  api({
    url: host + port.user + "auth/updatebutoken",
    method: "post",
    data: params,
  });
export const updateCGM = (params) =>
  api({
    url: host + port.user + "user/cgm/type",
    method: "get",
    params: params,
  });
// 添加一条事件
export const addEvent = (params) =>
  api({
    url: host + port.event + "event/add",
    method: "post",
    data: params,
  });
// 获取事件列表
export const getEvent = (params) =>
  api({
    url: host + port.event + "event/all/byId",
    method: "get",
    params: params,
  });
// 插入实时血糖
export const insertbg = (params) =>
  api({
    url: host + port.cgm + "cgm/insertbg",
    method: "post",
    params: params,
  });
// 按时间段查询血糖
export const getBGbyToken = (params) =>
  api({
    url: host + port.cgm + "cgm/getBGbyToken",
    method: "get",
    params: params,
  });
// 按时间段和ID查询关注人血糖
export const getSugerbyId = (params) =>
  api({
    url: host + port.cgm + "cgm/getBGbyId",
    method: "get",
    params: params,
  });
//  小时查询血糖
export const getbghours = (params) =>
  api({
    url: host + port.cgm + "cgm/getbghours",
    method: "get",
    params: params,
  });
// 获取最新血糖
export const getNew = (params) =>
  api({
    url: host + port.cgm + "cgm/bglatest",
    // url: 'http://192.168.110.68:20027/cgm/bglatest',
    method: "get",
    params: params,
  });
// 条件查询血糖按天数
export const getbgdays = (params) =>
  api({
    url: host + port.cgm + "cgm/getbgdays",
    method: "get",
    params: params,
  });
// 批量插入血糖
export const insertbgs = (params) =>
  api({
    url: host + port.cgm + "cgm/insertbgs",
    method: "post",
    data: params,
  });

// 获取token
export const getQniuToken = () =>
  api({
    url: host + port.user + "auth/getToken",
    method: "get",
  });

// 查询授权接口
export const getuserbytoken = () =>
  api({
    url: host + port.user + "auth/getuserbytoken",
    method: "get",
  });

// 根据id查询用户信息
export const getUserInfoById = (params) =>
  api({
    url: host + port.user + "auth/get/role2",
    method: "get",
    params: params,
  });
// 创建成功
export const createUser = (params) =>
  api({
    url: host + port.user + "auth/register",
    method: "post",
    data: params,
  });
// 获取医院列表
export const getStructList = (params) =>
  api({
    url: host + port.user + "auth/struct/selectAll",
    method: "get",
    params: params,
  });
// 获取医院下人员列表
export const getStructUser = (params) =>
  api({
    url: host + port.user + "auth/getorganuser",
    method: "get",
    params: params,
  });

// 更新组织树通过id
export const updateStruct = (params) =>
  api({
    url: host + port.user + "auth/struct/update",
    method: "post",
    data: params,
  });
// 插入关系
export const insertRelation = (params) =>
  api({
    url: host + port.user + "auth/relation/insert",
    method: "post",
    data: params,
  });

export const getData = () =>
  api({
    url: "https://api.sisensing.com/open/device/glucose",
    method: "get",
  });

//获取某个医院下面的一个病人病历
export const getCases = (params) =>
  api({
    url:
      host +
      port.user +
      `patient/case/get?structId=${params.structId}&patientId=${params.patientId}`,
    method: "get",
    data: params,
  });
// loop闭环操作列表
export const getLoopHandleList = (params) =>
  api({
    url: host + port.loop + "loop/apn/all?uid=" + params,
    method: "get",
  });

  // aaps闭环操作列表
  export const getAapsHandleList = (params) =>
    api({
      url: host + port.loop + "aaps/apn/all",
      method: "get",
      params
    });

//闭环治疗
export const loopTreatments = (params) =>
  api({
    url: host + port.loop + "api/v1/treatments",
    method: "get",
    params: params,
  });
// 硅基同步数据
export const synchronousGJ = (params) =>
  api({
    url: host + port.user + "gj/data",
    method: "get",
    params: params,
  });
//修改备注名
export const relationShowname = (params) =>
  api({
    url: host + port.user + "auth/relation/showname",
    method: "get",
    params: params,
  });
//获取基础率和临时基础率组合数组
export const getAllTempArry = (params) =>
  api({
    url: host +  "loop/api/v1/Basal",
    method: "get",
    params,
  });
