<template>
  <div id="loginView">
    <div
      style="
        margin-top: 6rem;
        color: #333;
        font-size: 2rem;
        font-weight: bold;
        text-align: center;
      "
    >
      账号密码登录
    </div>
    <!-- <div
      style="
        margin-top: 2rem;
        color: darkgray;
        font-size: 1rem;
        font-weight: 300;
        text-align: center;
      "
    >
      使用已注册的账号登录
    </div> -->
    <!-- <div
      style="
        margin-top: 0.2rem;
        color: darkgray;
        font-size: 1rem;
        font-weight: 300;
        text-align: center;
      "
    >
      账号默认为您注册的手机号
    </div> -->
    <div style="width: 90%; margin-left: 5%; margin-top: 6rem">
      <div
        style="
          background: #f5f7f7;
          width: 90%;
          margin-left: 5%;
          height: 4rem;
          line-height: 4rem;
          font-size: 1.2rem;
          border-radius: 2rem;
        "
      >
        <el-row>
          <el-col :span="4"
            ><div
              style="
                font-weight: bold;
                width: 100%;
                height: 4rem;
                text-align: center;
              "
            >
              +86
            </div></el-col
          >
          <el-col :span="20">
            <input
              placeholder="请输入手机号"
              type="text"
              pattern="d*"
              @input="handleInput"
              v-model="account.account"
              style="
                border: 0 solid #fff;
                height: 3rem;
                line-height: 3rem;
                background-color: #f5f7f7;
              "
            />
          </el-col>
        </el-row>
        <el-row
          style="
            background: #f5f7f7;
            margin-top: 2rem;
            height: 4rem;
            line-height: 4rem;
            font-size: 1.2rem;
            border-radius: 2rem;
          "
        >
          <el-col :span="4" style="height: 3rem; line-height: 4rem">
            <div style="float: right; margin-right: 7px">密码</div>
          </el-col>
          <el-col :span="20">
            <div :span="2" style="color: #7a7a7a; margin-left: 0px">
              <input
                placeholder="请输入密码"
                type="password"
                style="
                  border: 0 solid #fff;
                  height: 3rem;
                  line-height: 3rem;
                  background-color: #f5f7f7;
                "
                v-model="account.password"
              />
            </div>
          </el-col>
        </el-row>
      </div>
      <div style="margin-top: 8rem">
        <el-button
          style="
            background: #266eff;
            width: 80%;
            height: 48px;
            border-radius: 2rem;
          "
          type="primary"
          @click="login"
        >
          登录
        </el-button>
      </div>

      <div class="bottom">
        <div @click="setNewPass()">忘记密码?</div>
        <div class="line"></div>
        <div @click="goToCodeLogin">使用验证码登录</div>
      </div>
    </div>

    <!-- 闭环类型 -->
    <van-dialog
      v-model="hospitalDialog"
      class="hospitalDialog"
      title="选择闭环类型"
      showConfirmButton
      confirmButtonText="关闭"
    >
      <div class="dialog-box" @click="hospitalClick('loop')">loop</div>
      <div class="dialog-box" @click="hospitalClick('aaps')">aaps</div>
    </van-dialog>

    <!-- 设置新密码 -->
    <van-dialog
      v-model="setNewPassShow"
      title="忘记密码"
      show-cancel-button
      @confirm="confirmPassword"
    >
      <van-cell-group>
        <van-field
          v-model.trim="setPassForm.phone"
          label="手机号"
          :rules="[
            { required: true, message: '请填写手机号码', trigger: 'blur' },
            { validator: validatePhone, message: '请输入有效的手机号码' },
          ]"
        />
        <van-field
          v-model.trim="setPassForm.password"
          label="新密码"
          :rules="[
            { required: true, message: '请填写新密码', trigger: 'blur' },
          ]"
        />
        <van-field
          v-model.trim="setPassForm.code"
          label="验证码"
          :rules="[
            { required: true, message: '请填写验证码', trigger: 'blur' },
          ]"
        >
          <van-button
            slot="button"
            size="small"
            type="primary"
            @click="getCode"
            >{{ showGetCode ? "发送验证码" : timeOut + "秒后重试" }}</van-button
          >
        </van-field>
      </van-cell-group>
    </van-dialog>
  </div>
</template>
<script>
import Vue from "vue";
import { Toast } from "vant";
// eslint-disable-next-line no-unused-vars
import { getCode, login } from "../../api/userServices";
import { getHospitalByDoctor, getNewPassword } from "../../api/relationship";
export default {
  name: "LoginView",

  data() {
    return {
      account: {
        account: "",
        password: "",
      },
      hospitalDialog: false,
      hospitalList: [],
      setNewPassShow: false,
      setPassForm: {
        phone: "",
        password: "",
        code: "",
      },
      showGetCode: true,
      timeOut: 120,
    };
  },

  mounted() {
    const loginStatus = JSON.parse(localStorage.getItem("loginStatus"));
    if (loginStatus) {
      this.$router.push({ path: "/BloodSugar", query: { index: 0 } });
    }
  },

  methods: {
    confirmPassword() {
      if (
        this.setPassForm.phone &&
        this.setPassForm.password &&
        this.setPassForm.code
      ) {
        getNewPassword(this.setPassForm).then((res) => {
          if (res.data.code == 200) {
            this.$toast("修改成功");
          } else {
            this.$toast(res.data.msg);
          }
        });
      } else {
        this.$toast("请检查表单填写");
      }
    },
    getCode() {
      if (this.validatePhone(this.setPassForm.phone)) {
        this.showGetCode = false;
        this.timeOut = 120;
        getCode(this.setPassForm.phone);
        const _this = this;
        this.timer = setInterval(function () {
          if (_this.timeOut > 1) {
            _this.timeOut = _this.timeOut - 1;
          } else {
            _this.closeInterval();
          }
        }, 1000);
      } else {
        this.$toast("请正确填写手机号");
      }
    },
    closeInterval() {
      this.showGetCode = true;
      clearInterval(this.timer);
    },
    validatePhone(value) {
      console.log(value);
      const phonePattern = /^1[3-9]\d{9}$/;
      return phonePattern.test(value);
    },
    setNewPass() {
      this.setNewPassShow = true;
    },
    handleInput(e) {
      let value = e.target.value + "";
      let regSpace = /\s/;
      // 如果有空格，去掉空格
      if (regSpace.test(value)) {
        this.account.account = value.replace(/\s+/g, "");
        return;
      }
      // 限制输入位数
      if (value.length > 11) {
        this.account.account = value.slice(0, 11);
      }
    },
    login() {
      const params = {
        phone: this.account.account,
        password: this.account.password,
        type: 2,
      };
      login(params)
        .then((res) => {
          const code = res.data.code;
          if (code == 200 || code == 201) {
            const targetHigh = res.data.data.appuser.targetHigh
              ? res.data.data.appuser.targetHigh
              : "10";
            const targetLow = res.data.data.appuser.targetLow
              ? res.data.data.appuser.targetLow
              : "3.9";
            localStorage.setItem("targetHigh", targetHigh)
            localStorage.setItem("targetLow", targetLow)
            localStorage.setItem("token", res.data.data.token);
            localStorage.setItem(
              "appuser",
              JSON.stringify(res.data.data.appuser)
            );
            localStorage.setItem("loginStatus", true);
            const message = {
              token: res.data.data.token,
            };
            const androidMessage = JSON.stringify(message);
            try {
              const phoneType = localStorage.getItem("phoneType");
              if (phoneType == "Android") {
                window.android.setTokenToIOS(androidMessage);
              } else {
                window.webkit.messageHandlers.setTokenToIOS.postMessage(
                  message
                );
              }
            } catch (error) {}
            if (res.data.data.appuser.loopRemote == "aapsloop") {
              this.hospitalDialog = true;
            } else {
              this.$ws.start();
              this.$router.push({
                path: "/BloodSugar",
                query: { index: 0 },
              });
              Toast("登录成功！");
            }
          } else {
            Toast(res.data.code || "登录失败");
            // this.$router.push('/register');
          }
        })
        .catch((error) => {
          Toast(res.data.code || "登录失败");
        });
    },
    // 选择闭环类型
    hospitalClick(name) {
      let data = JSON.parse(localStorage.getItem("appuser"));
      const newData = {
        ...data,
        loopRemote: name,
      };
      localStorage.setItem("appuser", JSON.stringify(newData));
      this.$ws.start();

      this.hospitalDialog = false;
      this.$router.push({
        path: "/BloodSugar",
        query: { index: 0 },
      });
      Toast("登录成功！");
    },
    goToCodeLogin() {
      this.$router.push("/login");
    },
  },
};
</script>
<style scoped lang="less">
#loginView {
  width: 100%;
  height: 100vh;
  position: fixed;
  z-index: 100;
  top: 0;
  left: 0;
  background: url("../../static/pic/loginBG.jpg") 0% 88%;
}

.inputValue {
  border: 1px solid #424141;
  height: 3rem;
  line-height: 3rem;
  padding-left: 12px;
  border-radius: 8px;
}
input,
textarea {
  font-size: 16px;
}

::v-deep .hospitalDialog {
  .van-dialog__header {
    padding-top: 10px;
    font-weight: 700;
  }
}
.dialog-box {
  align-items: center;
  background-color: #dbe3f8;
  border-bottom: 3px solid #b0bee4;
  border-radius: 0.375rem;
  color: #6372a5;
  display: flex;
  font-size: 1.125rem;
  font-weight: 600;
  height: 3rem;
  justify-content: center;
  overflow: hidden;
  padding: 0 1rem;
  text-decoration: none;
  transition: 0.2s ease-in-out;
  transition-property: background-color, box-shadow, border;
  width: 70%;
  cursor: pointer;
  margin: 0 auto;
  margin-bottom: 10px;
}
.dialog-box:hover {
  background-color: #b0bee4;
  border-color: #98a7d4;
  color: #485584;
  outline: none;
}
.bottom {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 3rem;
  color: #409eff;
  .line {
    border-right: 1px solid #409eff;
    height: 1.2rem;
    margin: 0 2rem;
  }
}
::v-deep .van-dialog__header {
  padding: 10px;
}
::v-deep .van-button--primary {
  background-color: #409eff;
  border: 0;
}
</style>
