<template>
  <div class="loopHandleList">
    <van-nav-bar
      title="loop操作列表"
      left-arrow
      fixed
      left-text="返回"
      @click-left="goBackMineView"
      style="background-color: #f8f8f8"
    />
    <!-- card -->
    <div id="card">
      <div class="main" v-for="item in detailList" :key="item.id" @click="showDetail(item)">
        <!-- 事件图标 -->
        <img
          class="img"
          src="../../../static/pic/blueLoop.png"
          v-if="JSON.parse(item.json).type == 1"
          style="width: 44px; height: 44px"
        />
        <img
          class="img"
          src="../../../static/pic/redLoop.png"
          v-else-if="JSON.parse(item.json).type == 2"
          style="width: 44px; height: 44px"
        />
        <img class="img" src="../../../static/pic/wave.png" v-else-if="JSON.parse(item.json).type == 3" />
        <img class="img" src="../../../static/pic/cancelWave.png" v-else-if="JSON.parse(item.json).type == 4" />
        <img class="img" src="../../../static/pic/loopTS.png" v-else-if="JSON.parse(item.json).type == 5" />
        <img class="img" src="../../../static/pic/loopYDS.png" v-else-if="JSON.parse(item.json).type == 6" />
        <img class="img" src="../../../static/pic/base.png" v-else-if="JSON.parse(item.json).type == 7" />
        <img class="img" src="../../../static/pic/cancel.png" v-else-if="JSON.parse(item.json).type == 8" />
        <!-- 事件文字 -->
        <div class="text">
          <label class="title">
            <span v-if="JSON.parse(item.json).type == 1">开启闭环</span>
            <span v-else-if="JSON.parse(item.json).type == 2">关闭闭环</span>
            <span v-else-if="JSON.parse(item.json).type == 3">覆盖方波</span>
            <span v-else-if="JSON.parse(item.json).type == 4">取消覆盖</span>
            <span v-else-if="JSON.parse(item.json).type == 5">添加碳水</span>
            <span v-else-if="JSON.parse(item.json).type == 6">大剂量</span>
            <span v-else-if="JSON.parse(item.json).type == 7">临时基础率</span>
            <span v-else-if="JSON.parse(item.json).type == 8">取消临时</span>
          </label>
          <label class="detail">操作时间：{{ item.createTime }}</label>
          <!-- 添加上太丑了 -->
          <!-- <label
            class="detail"
            style="line-height: 18px"
            v-show="item.status == 0"
            >{{ item.info }}</label
          > -->
        </div>
        <!-- 状态图标 -->
        <img class="icon" v-if="item.status == 1" src="../../../static/pic/success.png" alt="" />
        <img class="icon" v-else-if="item.status == 0" src="../../../static/pic/fail.png" alt="" />
        <img class="icon" v-else src="../../../static/pic/ready.png" alt="" />
      </div>
      <!-- 事件信息 -->
      <van-dialog v-model="isShow" :title="title">
        <label class="detail" v-show="(this.type != 4) & (this.type != 8)">动态密码：{{ json.otp }}</label>
        <label class="detail" v-show="this.type == 3">持续时间：{{ json.minutes }}分钟</label>
        <label class="detail" v-show="this.type == 5">碳水：{{ json.carbs }}g</label>
        <label class="detail" v-show="this.type == 5">吸收时间：{{ json.carbsHouers }}小时</label>
        <label class="detail" v-show="this.type == 6">大剂量：{{ json.bolus }}U</label>
        <label class="detail" v-show="this.type == 7">临时基础率：{{ json.perHour }}U/小时</label>
        <label class="detail" v-show="this.type == 7">持续时间：{{ json.baseHouers }}小时</label>
        <label class="detail">操作时间：{{ createTime }}</label>
        <label
          class="detail"
          style="line-height: 18px; width: 280px; word-wrap: break-word; text-align: center; margin-left: 20px"
          v-show="status == 0"
        >
          {{ info }}
        </label>
      </van-dialog>
    </div>
  </div>
</template>

<script>
  import { getLoopHandleList, getAapsHandleList } from '@/api/relationship'
  export default {
    data() {
      return {
        detailList: [],
        json: {},
        createTime: '',
        imgUrl: '',
        isShow: false,
        type: '',
        title: '',
        status: '',
        info: '',
        loopRemote: '',
      }
    },
    created() {
      this.loopRemote = JSON.parse(localStorage.getItem('appuser')).loopRemote
      this.getList()
    },
    methods: {
      showDetail(item) {
        this.status = item.status
        this.info = item.info
        this.json = JSON.parse(item.json)
        this.type = JSON.parse(item.json).type
        this.createTime = item.createTime
        if (this.type == 1) {
          this.title = '开启闭环'
        } else if (this.type == 2) {
          this.title = '关闭闭环'
        } else if (this.type == 3) {
          this.title = '覆盖方波'
        } else if (this.type == 4) {
          this.title = '取消覆盖'
        } else if (this.type == 5) {
          this.title = '添加碳水'
        } else if (this.type == 6) {
          this.title = '大剂量'
        } else if (this.type == 7) {
          this.title = '临时基础率'
        } else if (this.type == 8) {
          this.title = '取消临时'
        }
        this.$nextTick(() => {
          this.isShow = true
        })
      },
      getList() {
        const uid = JSON.parse(localStorage.getItem('appuser')).id
        if (this.loopRemote == 'loop') {
          getLoopHandleList(uid).then(({ data }) => {
            this.detailList = data.data
          })
        } else {
          let params = {
            uid: JSON.parse(localStorage.getItem('appuser')).id,
            phone: JSON.parse(localStorage.getItem('appuser')).iphone,
          }
          getAapsHandleList(params).then(({ data }) => {
            this.detailList = data.data
          })
        }
      },
      goBackMineView() {
        this.$router.go(-1)
      },
    },
  }
</script>

<style lang="less" scoped>
  #card {
    margin-top: 55px;
  }
  .loopHandleList {
    padding-bottom: 7rem;
  }
  ::v-deep .van-nav-bar__content {
    .van-nav-bar__title {
      color: #000000;
      font-weight: bold;
    }
    .van-nav-bar__text {
      color: #000000;
    }
    .van-icon {
      &::before {
        color: #000;
      }
    }
  }
  .main {
    position: relative;
    padding-top: 4px;
    width: 95%;
    height: 80px;
    background-color: white;
    background: rgba(255, 255, 255, 0.5);
    box-shadow: 0px 3px 7px 0px rgb(0 0 0 / 35%);
    border-radius: 10px;
    margin: 10px;
  }
  .img {
    position: relative;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    top: 50%;
    transform: translateY(-50%);
    margin-left: 20px;
    float: left;
  }
  .text {
    position: relative;
    top: 50%;
    transform: translateY(-50%);
    text-align: left;
    margin-left: 25%;
  }
  .title {
    display: block;
    font-size: 18px;
    margin: 5px 0;
    font-weight: bold;
    /* text-shadow: 1px 1px 1px grey; */
  }
  .icon {
    position: absolute;
    right: 15px;
    top: 12px;
    width: 20px;
    height: 20px;
  }
  .detail {
    display: block;
    font-size: 14px;
    display: block;
    margin-top: 10px;
    color: #7b7b7b;
  }
  ::v-deep .van-dialog {
    .van-dialog__content {
      padding-bottom: 20px;
    }
  }
</style>
