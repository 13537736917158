<template>
  <div id="app">
    <homePages></homePages>
  </div>
</template>
<script>
import homePages from "./pages/homePages/index.vue";
import { mapState, mapActions, mapMutations } from "vuex";
export default {
  created() {
    this.fbiSF();
    this.getPhoneType();
  },
  components: { homePages },
  methods: {
    getPhoneType() {
      var u = navigator.userAgent;
      var isAndroid = u.indexOf("Android") > -1 || u.indexOf("Linux") > -1; //android终端或者uc浏览器
      var isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端

      if (isAndroid) {
        //Android
        localStorage.setItem("phoneType", "Android");
      } else if (isiOS) {
        //ios
        localStorage.setItem("phoneType", "ios");
      } else {
        localStorage.setItem("phoneType", "web");
      }
    },
    fbiSF() {
      // 禁用双指放大
      document.documentElement.addEventListener(
        "touchstart",
        function (event) {
          if (event.touches.length > 1) {
            event.preventDefault();
          }
        },
        { passive: false }
      );

      // 禁用双击放大
      let lastTouchEnd = 0;
      document.documentElement.addEventListener(
        "touchend",
        function (event) {
          const now = Date.now();
          if (now - lastTouchEnd <= 300) {
            event.preventDefault();
          }
          lastTouchEnd = now;
        },
        { passive: false }
      );
    },
  },
};
</script>
<style lang="less">
#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #fff;
  text-align: center;
  color: #2c3e50;
  width: 100vw;
  font-size: 1rem;
  font-weight: 500;
}
@media screen and (-webkit-min-device-pixel-ratio: 0) {
  select,
  textarea,
  input {
    font-size: 16px;
  }
}
html {
  font-size: 12px;
  line-height: 12px;
}
</style>
