<template>
  <div class="loopHandle">
    <van-nav-bar :left-text="leftText" style="background-color: #f8f8f8">
      <template #right>
        <div @click="toHandleList">
          <span class="handleList"><img src="@/static/pic/list.png" /></span
          ><span class="handleText" style="color: #000">操作日志</span>
        </div>
      </template>
    </van-nav-bar>

    <div class="box">
      <div class="panel">
        <div
          v-for="item in handleList"
          :key="item.title"
          @click="handleLoop(item)"
        >
          <img :src="item.imgUrl" alt="" />
          <span>{{ item.title }}</span>
        </div>
      </div>
      <div class="handle">
        <div class="title">{{ title }}</div>
        <van-form ref="form" label-width="120px" :model="form">
          <!-- 覆盖方波 -->
          <van-field
            v-model="form.minutes"
            label="持续"
            type="number"
            placeholder="持续时间（分钟）"
            :rules="[{ required: true }]"
            v-if="type == 3"
          />
          <van-field
            v-model="form.fugainame"
            is-link
            @click="nameShow = true"
            label="原因"
            placeholder="请选择原因"
            :rules="[{ required: true }]"
            v-if="type == 3"
            readonly
          />
          <van-popup v-model="nameShow" round position="bottom">
            <van-picker
              show-toolbar
              title="原因"
              :columns="columns2"
              @cancel="onCancel2"
              @confirm="onConfirm2"
            />
          </van-popup>
          <!-- 添加碳水 -->
          <van-field
            v-model="form.carbs"
            label="碳水化合物"
            type="number"
            placeholder="总量（g）"
            :rules="[{ required: true }]"
            v-if="type == 5"
          />
          <van-field
            v-model="form.carbsHouers"
            label="吸收时间"
            type="digit"
            placeholder="请输入吸收时间（h）"
            :rules="[{ required: true }]"
            v-if="type == 5"
          />
          <!-- 大剂量 -->
          <van-field
            v-model="form.bolus"
            label="大剂量"
            type="number"
            placeholder="总量（U）"
            :rules="[{ required: true }]"
            v-if="type == 6"
          />
          <!-- 临时基础率 -->
          <van-field
            v-model="form.perHour"
            is-link
            @click="perHourShow = true"
            label="临时基础率"
            type="number"
            placeholder="U/小时"
            :rules="[{ required: true }]"
            v-if="type == 7"
            readonly
          />
          <van-popup v-model="perHourShow" round position="bottom">
            <van-picker
              show-toolbar
              title="临时基础率"
              :columns="columns1"
              @cancel="onCancel1"
              @confirm="onConfirm1"
            />
          </van-popup>
          <van-field
            v-model="form.baseHouers"
            is-link
            @click="baseHouerShow = true"
            label="持续时间"
            placeholder="请选择持续时间（h）"
            :rules="[{ required: true }]"
            v-if="type == 7"
            readonly
          />
          <van-popup v-model="baseHouerShow" round position="bottom">
            <van-picker
              show-toolbar
              title="持续时间（h）"
              :columns="columns"
              @cancel="onCancel"
              @confirm="onConfirm"
            />
          </van-popup>
          <!-- 动态密码 -->
          <van-field
            v-model="form.otp"
            label="动态密码"
            placeholder="请输入动态密码"
            :rules="[{ required: true }]"
          />
        </van-form>
      </div>
      <van-button :loading="loading" type="button" @click="submit"
        >提交</van-button
      >
    </div>
    <!-- <div style="margin-top: 35%" v-else>
      <van-empty description="aaps远程操作功能正在开发！" />
    </div> -->
    <van-dialog
      v-model="showIcon"
      :title="iconTitle"
      @confirm="confirm()"
      :showConfirmButton="isShowButton"
    >
      <img class="icon" src="../../../static/pic/ready.png" ref="img" alt="" />
      <p
        v-show="faultNews"
        style="line-height: 20px; word-wrap: break-word; margin: 16px 20px"
      >
        {{ faultNews }}
      </p>
      <div class="overTime" v-show="isOverTime">
        <p>1.请确认闭环已成功绑定理糖宝</p>
        <p>2.请确认已打开蓝牙</p>
        <p>3.请确认闭环通知已打开</p>
        <p>4.请确认闭环成功连接泵</p>
        <p>5.请确认网络状态良好</p>
      </div>
    </van-dialog>
  </div>
</template>

<script>
import {
  postLoop,
  getLoopOption,
  getLoopOne,
  postAaps,
  getAapsOne,
} from "../../../api/relationship";
import success from "../../../static/pic/success.png";
import fail from "../../../static/pic/fail.png";
import ready from "../../../static/pic/ready.png";
export default {
  data() {
    return {
      loopRemote: null,
      leftText: "",
      handleList: [
        {
          title: "开启闭环",
          imgUrl: require("../../../static/pic/blueLoop.png"),
          type: 1,
        },
        {
          title: "关闭闭环",
          imgUrl: require("../../../static/pic/redLoop.png"),
          type: 2,
        },
        {
          title: "覆盖方波",
          imgUrl: require("../../../static/pic/wave.png"),
          type: 3,
        },
        {
          title: "取消方波",
          imgUrl: require("../../../static/pic/cancelWave.png"),
          type: 4,
        },
        {
          title: "大剂量",
          imgUrl: require("../../../static/pic/loopYDS.png"),
          type: 6,
        },
        {
          title: "添加碳水",
          imgUrl: require("../../../static/pic/loopTS.png"),
          type: 5,
        },
        {
          title: "临时基础率",
          imgUrl: require("../../../static/pic/base.png"),
          type: 7,
        },
        {
          title: "取消临时",
          imgUrl: require("../../../static/pic/cancel.png"),
          type: 8,
        },
      ],
      columns: ["0.5", "1", "1.5", "2", "2.5", "3"], // 临时基础率持续时间列表
      columns1: ["0", "0.05", "0.1", "0.15", "0.2", "0.25", "0.3"], // 临时基础率列表
      columns2: [], // 覆盖方波原因列表
      form: {},
      params: {},
      uid: JSON.parse(localStorage.getItem("appuser")).id,
      title: "大剂量",
      type: 6,
      nameShow: false,
      baseHouerShow: false,
      perHourShow: false,
      loading: false,
      showIcon: false,
      iconTitle: "等待中...",
      timer: null,
      timeNum: 0,
      isShowButton: false,
      faultNews: "",
      isOverTime: false,
      loopRemote: JSON.parse(localStorage.getItem("appuser")).loopRemote,
    };
  },
  created() {
    this.$once("hook:beforeDestroy", () => {
      clearInterval(this.timer); // 停止
    });

    this.loopRemote = JSON.parse(localStorage.getItem("appuser")).loopRemote;
    this.leftText = this.loopRemote == "loop" ? "loop远程操作" : "aaps远程操作";
    if(this.loopRemote == "aaps") {
      this.handleList =  [
        {
          title: "开启闭环",
          imgUrl: require("../../../static/pic/blueLoop.png"),
          type: 1,
        },
        {
          title: "关闭闭环",
          imgUrl: require("../../../static/pic/redLoop.png"),
          type: 2,
        },
        {
          title: "大剂量",
          imgUrl: require("../../../static/pic/loopYDS.png"),
          type: 6,
        },
        {
          title: "添加碳水",
          imgUrl: require("../../../static/pic/loopTS.png"),
          type: 5,
        },
      ]
    }
  },
  methods: {
    goBackMineView() {
      this.$router.go(-1);
    },
    handleLoop(item) {
      if (item.type == 3) {
        const uid = JSON.parse(localStorage.getItem("appuser")).id;
        const params = {
          uid: uid,
          type: this.loopRemote,
        };
        getLoopOption(params).then((data) => {
          const waveOption = data.data.loopSettings.overridePresets;
          if (!waveOption.length) {
            this.$toast({
              type: "html",
              message: "暂无可用方波模式<br>请联系主控设备预设覆盖方波后使用",
              duration: 3000,
            });
          } else {
            waveOption.forEach((item) => {
              this.columns2.push(item.name);
            });
            this.form = {};
            this.title = item.title;
            this.type = item.type;
          }
        });
      } else {
        this.form = {};
        this.title = item.title;
        this.type = item.type;
      }
    },
    toHandleList() {
      this.$router.push("/loopHandleList");
    },
    // 临时基础率持续时间
    onConfirm(value, index) {
      this.form.baseHouers = value;
      this.baseHouerShow = false;
    },
    onCancel() {
      this.baseHouerShow = false;
    },
    // 临时基础率
    onConfirm1(value, index) {
      console.log(value);
      this.form.perHour = value;
      this.perHourShow = false;
    },
    onCancel1() {
      this.perHourShow = false;
    },
    // 覆盖方波原因
    onConfirm2(value, index) {
      console.log(value);
      this.form.fugainame = value;
      this.nameShow = false;
    },
    onCancel2() {
      this.nameShow = false;
    },
    confirm() {
      clearInterval(this.timer);
      this.$refs.img.src = ready;
      this.iconTitle = "等待中...";
      this.faultNews = "";
      this.isShowButton = false;
      this.timeNum = 0;
      this.isOverTime = false;
    },
    submit() {
      this.$refs.form
        .validate()
        .then(() => {
          this.loading = true;
          if (this.type == 1) {
            this.params = {
              uid: this.uid,
              type: 1,
              otp: this.form.otp,
            };
          } else if (this.type == 2) {
            this.params = {
              uid: this.uid,
              type: 2,
              otp: this.form.otp,
            };
          } else if (this.type == 3) {
            this.params = {
              uid: this.uid,
              type: 3,
              minutes: this.form.minutes,
              fugainame: this.form.fugainame,
              otp: this.form.otp,
            };
          } else if (this.type == 4) {
            this.params = {
              uid: this.uid,
              type: 4,
              otp: this.form.otp,
            };
          } else if (this.type == 5) {
            this.params = {
              uid: this.uid,
              type: 5,
              carbs: Number(this.form.carbs),
              carbsHouers: Number(this.form.carbsHouers),
              otp: this.form.otp,
            };
          } else if (this.type == 6) {
            this.params = {
              uid: this.uid,
              type: 6,
              bolus: Number(this.form.bolus),
              otp: this.form.otp,
            };
          } else if (this.type == 7) {
            this.params = {
              uid: this.uid,
              type: 7,
              perHour: this.form.perHour,
              baseHouers: this.form.baseHouers,
              otp: this.form.otp,
            };
          } else if (this.type == 8) {
            this.params = {
              uid: this.uid,
              type: 8,
              otp: this.form.otp,
            };
          }
          if (this.loopRemote == "loop") {
            postLoop(this.params).then(({ data }) => {
              if (data.code == 200) {
                this.loading = false;
                this.form = {};
                this.showIcon = true;
                this.timer = setInterval(() => {
                  this.timeNum++;
                  getLoopOne({ id: data.data.id }).then(({ data }) => {
                    console.log(data);
                    if (data.data.status == 1) {
                      // 如果30秒后又成功了就要把超时信息隐藏
                      this.isOverTime = false;
                      this.$refs.img.src = success;
                      this.iconTitle = "操作成功";
                      clearInterval(this.timer);
                      this.isShowButton = true;
                    } else if (data.data.status == 0) {
                      // 如果30秒后又失败了就要把超时信息隐藏
                      this.isOverTime = false;
                      this.faultNews = data.data.info;
                      this.$refs.img.src = fail;
                      this.iconTitle = "操作失败";
                      clearInterval(this.timer);
                      this.isShowButton = true;
                    }
                  });
                }, 1000);
              } else {
                this.loading = false;
                this.$toast(data.msg || "提交失败");
                this.form = {};
              }
            }).catch(() => {
              this.loading = false;
                this.$toast(data.msg || "提交失败");
                this.form = {};
            });
          } else {
            this.params.phone = JSON.parse(localStorage.getItem("appuser")).iphone
            postAaps(this.params).then(({ data }) => {
              if (data.code == 200) {
                this.loading = false;
                this.form = {};
                this.showIcon = true;
                this.timer = setInterval(() => {
                  this.timeNum++;
                  getAapsOne({ id: data.data.id,phone:JSON.parse(localStorage.getItem("appuser")).iphone }).then(({ data }) => {
                    if (data.data.status == 1) {
                      // 如果30秒后又成功了就要把超时信息隐藏
                      this.isOverTime = false;
                      this.$refs.img.src = success;
                      this.iconTitle = "操作成功";
                      clearInterval(this.timer);
                      this.isShowButton = true;
                    } else if (data.data.status == 0) {
                      // 如果30秒后又失败了就要把超时信息隐藏
                      this.isOverTime = false;
                      this.faultNews = data.data.info;
                      this.$refs.img.src = fail;
                      this.iconTitle = "操作失败";
                      clearInterval(this.timer);
                      this.isShowButton = true;
                    }
                  });
                }, 1000);
              } else {
                this.loading = false;
                this.$toast(data.msg || "提交失败");
                this.form = {};
              }
            }).catch(() => {
              this.loading = false;
                this.$toast(data.msg || "提交失败");
                this.form = {};
            });
          }
        })
        .catch(() => {
          this.$toast("请检查表单填写");
        });
    },
  },
  watch: {
    timeNum(newVal, oldVal) {
      if (newVal >= 30) {
        this.isShowButton = true;
        this.isOverTime = true;
        this.iconTitle = "操作超时";
      }
    },
  },
};
</script>

<style lang="less" scoped>
.loopHandle {
  position: relative;
  background-color: #f8f8f8;
  height: 100vh;
}
::v-deep .van-nav-bar__content {
  .van-nav-bar__title {
    color: #000000;
    font-weight: bold;
  }
  .van-nav-bar__text {
    color: #000000;
    font-size: 18px;
    font-weight: bold;
  }
  .van-icon {
    &::before {
      color: #000;
    }
  }
}
::v-deep .van-popup {
  span {
    display: block;
  }
}
::v-deep .van-ellipsis {
  line-height: 18px;
}
.handleList {
  position: absolute;
  top: 15px;
  right: 80px;
  color: #000;
  z-index: 99;
  img {
    width: 17px;
  }
}
.handleText {
  font-size: 16px;
  font-weight: 700;
}
.box {
  padding: 0 10px;
}
.panel {
  margin-top: 5px;
  display: flex;
  flex-flow: wrap;
  border-radius: 10px;
  background-color: #fff;
  & > div {
    margin-top: 10px;
    width: 25%;
    height: 70px;
    color: #262626;
    font-weight: bold;
    display: flex;
    flex-direction: column;
    align-items: center;
    img {
      margin-bottom: 8px;
      width: 40px;
      height: 40px;
      border-radius: 50%;
    }
  }
}
.handle {
  margin-top: 10px;
  border-radius: 10px;
  overflow: hidden;
  .title {
    line-height: 44px;
    width: 100%;
    height: 44px;
    font-size: 16px;
    font-weight: bold;
    background-color: #fff;
  }
}
button {
  margin-top: 10px;
  background-color: #07c160;
  border-radius: 6px;
  width: 100%;
  height: 44px;
  border: 0;
  color: #fff;
  font-size: 14px;
}
::v-deep.van-dialog {
  .van-dialog__header {
    font-weight: bold;
  }
  .van-dialog__content {
    margin: 20px 0;
  }
  img {
    width: 60px;
  }
}
</style>
