<template>
  <div class="container">
    <div id="miniChart" style="width: 100%; height: 300px">
      <!-- 事件标记图 -->
      <div
        class="marker"
        :id="item.title + 'marker'"
        style="width: 100%; height: 5rem"
      ></div>
      <!-- 血糖图 -->
      <div
        v-show="item"
        :id="item.title"
        style="width: 100%; height: 300px"
      ></div>
      <!-- 临时基础率梯形图 -->
      <div
        class="temp"
        :id="item.title + 'temp'"
        ref="temp"
        style="width: 100%; height: 4.7rem"
      ></div>
    </div>
    <!-- <div style="width: 18%; height: 300px; position: relative; top: -30px">
      <div class="loopBox">
        <div class="loopTip" style="background: #80ccff">闭环数据</div>
        <div class="loopTitle">
          基础率胰岛素合计:
          <span class="loopUnit">{{ basalAmount ? basalAmount : "0" }}U</span>
        </div>
        <div class="loopTitle">
          每日胰岛素合计:
          <span class="loopUnit"
            >{{ insulinAmount ? insulinAmount : "0" }}U</span
          >
        </div>
        <div class="loopTitle">
          碳水化合物总摄入量:
          <span class="loopUnit">{{ carbAmount ? carbAmount : "0" }}g</span>
        </div>
      </div>
    </div> -->
    <!-- <div style="width: 32%; height: 300px; position: relative; top: -30px">
      <div class="loopBox">
        <div class="loopTip"></div>
        <div class="sugarTitle">
          低血糖:
          <span class="loopUnit">
            {{ item.lowRate ? item.lowRate : "-" }}
          </span>
        </div>
        <div class="sugarTitle">
          正常:
          <span class="loopUnit">
            {{ item.normalRate ? item.normalRate : "-" }}
          </span>
        </div>
        <div class="sugarTitle">
          高血糖:
          <span class="loopUnit">
            {{ item.highRate ? item.highRate : "-" }}
          </span>
        </div>
        <div class="sugarTitle">
          最小值:
          <span class="loopUnit">
            {{ item.minRate ? item.minRate : "-" }}
          </span>
        </div>
        <div class="sugarTitle">
          最大值:
          <span class="loopUnit">
            {{ item.maxRate ? item.maxRate : "-" }}
          </span>
        </div>
        <div class="sugarTitle">
          平均:
          <span class="loopUnit">
            {{ item.averageRate ? item.averageRate : "-" }}
          </span>
        </div>
      </div>
    </div> -->
  </div>
</template>
<script>
import { Line, G2, Scatter } from "@antv/g2plot";
import {
  getEvent,
  getLoopHandleList,
  getAapsHandleList,
  loopTreatments,
} from "@/api/userServices";
const G = G2.getEngine("canvas");
export default {
  props: {
    item: {
      type: Object,
    },
  },
  data() {
    return {
      basalAmount: "",
      insulinAmount: "",
      carbAmount: "",
      line: null,
      scatterPlot2: null,
      tempPlot: null,
      eventArr: [], // 事件列表
      tempBasal: [], // 临时基础率
      CorrectionBolus: [], // 胰岛素
      SuspendPump: [], // 泵
      CarbCorrection: [], // 碳水
      TemporaryOverride: [], // 覆盖方波
      loopType: "",
      maxRate: "",
      tickInterval: 1000 * 60 * 60,
      loopRemote: ''
    };
  },
  created() {
    this.loopRemote = JSON.parse(localStorage.getItem("appuser")).loopRemote;
  },
  mounted() {
    this.getTreat();
    this.getAllEvent();
    setTimeout(() => {
      this.drawChart();
    }, 300);
  },
  methods: {
    // 获取临时基础率
    getTreat() {
      this.tempBasal = [];
      const uid = this.$route.query.id;
      const params = {
        uid: uid,
        start: this.item.start,
        end: this.item.end,
      };
      loopTreatments(params).then(({ data }) => {
        // 去重
        const res = new Map();
        let arr = data.treatments.filter(
          (arr) =>
            !res.has(arr.syncIdentifier) && res.set(arr.syncIdentifier, 1)
        );
        // this.basal = data.profile.store.Default.basal[0].value //基础率
        // 把所有治疗数据分组
        arr.forEach((item) => {
          // 临时基础率分组
          if (item.eventType === "Temp Basal") {
            item.rate = item.rate * 1;
            this.tempBasal.push(item);
          }
          // 胰岛素 分组
          else if (item.eventType === "Correction Bolus") {
            this.CorrectionBolus.push(item);
          }
          // 泵分组
          else if (item.eventType === "Suspend Pump") {
            this.SuspendPump.push(item);
          }
          // 碳水分组
          else if (item.eventType === "Carb Correction") {
            this.CarbCorrection.push(item);
          }
          // 覆盖方波分组
          else if (item.eventType === "Temporary Override") {
            this.TemporaryOverride.push(item);
          }
        });
        let rateArr = [];
        this.tempBasal.forEach((item) => {
          rateArr.push(item.rate);
        });
        let insulinArr = [];
        this.CorrectionBolus.forEach((item) => {
          insulinArr.push(item.insulin);
        });
        let carbArr = [];
        this.CarbCorrection.forEach((item) => {
          carbArr.push(item.insulin);
        });
        // 获取基础率里面的最大值
        this.maxRate = Math.max(...rateArr);
        // 获取基础率胰岛素合计
        this.basalAmount = this.tempBasal.length
          ? Number(eval(rateArr.join("+"))).toFixed(2)
          : 0;
        // 每日胰岛素合计
        this.insulinAmount = this.CorrectionBolus.length
          ? Number(eval(insulinArr.join("+"))).toFixed(2)
          : 0;
        // 碳水化合物总摄入量
        this.carbAmount = this.CarbCorrection.length
          ? Number(eval(carbArr.join("+"))).toFixed(2)
          : 0;
        this.getTempChart();
      });
    },

    // 获取所有事件
    getAllEvent() {
      this.eventArr = [];
      // 获取糖仁云事件
      const params = {
        id: this.$route.query.id,
        start: this.$moment(this.item.start).valueOf(),
        end: this.$moment(this.item.end).valueOf(),
      };
      getEvent(params).then(({ data }) => {
        data.data.forEach((item) => {
          item.v = 0;
          this.eventArr.push(item);
        });
      });

      // 获取远程操作事件
      // const id = this.$route.query.id;
      // if(this.loopRemote == "loop") {
      //   getLoopHandleList(id).then(({ data }) => {
      //   data.data.forEach((item) => {
      //     if (item.status == 1) {
      //       item.v = 0;
      //       this.eventArr.push(item);
      //     }
      //   });
      // });
      // } else {
      //   let params = {
      //     uid: id,
      //     phone: JSON.parse(localStorage.getItem("appuser")).iphone,
      //   }
      //   getAapsHandleList(params).then(({ data }) => {
      //   data.data.forEach((item) => {
      //     if (item.status == 1) {
      //       item.v = 0;
      //       this.eventArr.push(item);
      //     }
      //   });
      // });
      // }

      // 获取 ns 事件
      const nsParams = {
        uid: this.$route.query.id,
        start: this.$moment(this.item.start).format("YYYY-MM-DD HH:mm:ss"),
        end: this.$moment(this.item.end).format("YYYY-MM-DD HH:mm:ss"),
      };
      loopTreatments(nsParams).then(({ data }) => {
        data.treatments.forEach((item) => {
          if (item.eventType === "Temp Basal") {
          }
          // 胰岛素
          else if (item.eventType === "Correction Bolus") {
            item.createTime = item.create_time;
            item.v = -1;
            this.eventArr.push(item); // 添加ns事件---胰岛素
          }
          // 碳水分组
          else if (item.eventType === "Carb Correction") {
            item.createTime = item.create_time;
            item.v = -0.5;
            this.eventArr.push(item); // 添加ns事件---碳水
          }
          // 覆盖方波分组
          else if (item.eventType === "Temporary Override") {
            item.createTime = item.create_time;
            item.v = 0;
            this.eventArr.push(item); // 添加ns事件---覆盖方波
          }
          // 泵分组
          else if (item.eventType === "Suspend Pump") {
            item.createTime = item.create_time;
            item.v = 0;
            this.eventArr.push(item);
          }
          // 其他
          else {
            item.createTime = item.create_time;
            item.v = 0;
            this.eventArr.push(item);
          }
        });
        this.getChart2(); // 在这里调用chart2 是为了保证事件都获取到之后在渲染事件图标
      });
    },

    drawChart() {
      const data = this.item.arr;
      this.line = new Line(this.item.title, {
        data,
        appendPadding: [50, 5, 10, 5],
        xField: "date",
        yField: "data",
        xAxis: {
          type: "time",
          max: this.$moment(this.item.end).valueOf(),
          min: this.$moment(this.item.start).valueOf(),
          mask: "HH:mm", // 显示格式为小时:分钟
          tickMethod: (scale) => {
            const { min, max, tickCount } = scale;
            const ticks = [];
            for (let i = min; i <= max; i += this.tickInterval) {
              ticks.push(i);
            }
            return ticks;
          },
          // label: {
          //   formatter: (v) => {
          //     return v.substring(11, 16)
          //   },
          // },
        },
        yAxis: {
          nice: true,
          min: 0,
          max: 20,
          grid: {
            line: {
              style: {
                lineWidth: 2,
                lineDash: [4, 5],
              },
            },
          },
        },
        tooltip: {
          formatter: (datum) => {
            //内容库内容
            return { name: "血糖", value: datum.data };
          },
        },
        smooth: true,
        annotations: [
          {
            type: "region",
            start: ["min", 3.9],
            end: ["max", 10],
            style: {
              stroke: "#edf0f2",
              lineDash: [2, 2],
            },
          },

          {
            type: "regionFilter",
            start: ["min", 10],
            end: ["max", 20],
            color: "#F4664A",
          },
          {
            type: "text",
            position: ["min", 10],
            content: "10",
            style: {
              fill: "#F4664A",
            },
          },
          {
            type: "regionFilter",
            start: ["min", 0],
            end: ["max", 3.9],
            color: "#ffaa29",
          },
          {
            type: "text",
            position: ["min", 3.9],
            content: "3.9",
            style: {
              fill: "#ffaa29",
            },
          },
        ],
      });
      this.line.render();
    },

    getChart2() {
      const data = this.eventArr;
      this.scatterPlot2 = new Scatter(this.item.title + "marker", {
        appendPadding: [5, 15, 0, -4],
        data,
        xField: "createTime",
        yField: "v",
        size: 5,
        // 点换成相应图片
        label: {
          offsetY: 0,
          content: (item) => {
            this.loopType = item.json ? JSON.parse(item.json).type : "";
            if (item.type == "kfy") {
              const group = new G.Group({});
              group.addShape({
                type: "image",
                attrs: {
                  x: 0,
                  y: 12,
                  width: 14,
                  height: 14,
                  img: require("../../../static/pic/kfytr.png"),
                },
              });
              // group.addShape({
              //   type: 'text',
              //   attrs: {
              //     x: 6,
              //     y: 2,
              //     text: item.type,
              //     textAlign: 'center',
              //     textBaseline: 'top',
              //     fill: '#000'
              //   }
              // })
              return group;
            } else if (item.type == "ts") {
              const group = new G.Group({});
              group.addShape({
                type: "image",
                attrs: {
                  x: 0,
                  y: 12,
                  width: 14,
                  height: 14,
                  img: "https://i.postimg.cc/sxTXkccS/01.png",
                },
              });
              return group;
            } else if (item.type == "yds") {
              const group = new G.Group({});
              group.addShape({
                type: "image",
                attrs: {
                  x: 0,
                  y: 12,
                  width: 14,
                  height: 14,
                  img: require("../../../static/pic/ydstr.png"),
                },
              });
              return group;
            } else if (item.type == "yd") {
              const group = new G.Group({});
              group.addShape({
                type: "image",
                attrs: {
                  x: 0,
                  y: 12,
                  width: 14,
                  height: 14,
                  img: "https://i.postimg.cc/rwxTWjrJ/image.png",
                },
              });
              return group;
            } else if (item.type == "zx") {
              const group = new G.Group({});
              group.addShape({
                type: "image",
                attrs: {
                  x: 0,
                  y: 12,
                  width: 14,
                  height: 14,
                  img: require("../../../static/pic/zxtr.png"),
                },
              });
              return group;
            } else if (this.loopType == 1) {
              const group = new G.Group({});
              group.addShape({
                type: "image",
                attrs: {
                  x: 0,
                  y: 12,
                  width: 14,
                  height: 14,
                  img: require("../../../static/pic/blueLoop.png"),
                },
              });
              return group;
            } else if (this.loopType == 2) {
              const group = new G.Group({});
              group.addShape({
                type: "image",
                attrs: {
                  x: 0,
                  y: 12,
                  width: 14,
                  height: 14,
                  img: require("../../../static/pic/redLoop.png"),
                },
              });
              return group;
            } else if (this.loopType == 3) {
              const group = new G.Group({});
              group.addShape({
                type: "image",
                attrs: {
                  x: 0,
                  y: 12,
                  width: 14,
                  height: 14,
                  img: require("../../../static/pic/wave.png"),
                },
              });
              return group;
            } else if (this.loopType == 4) {
              const group = new G.Group({});
              group.addShape({
                type: "image",
                attrs: {
                  x: 0,
                  y: 12,
                  width: 14,
                  height: 14,
                  img: require("../../../static/pic/cancelWave.png"),
                },
              });
              return group;
            } else if (this.loopType == 5) {
              const group = new G.Group({});
              group.addShape({
                type: "image",
                attrs: {
                  x: 0,
                  y: 12,
                  width: 14,
                  height: 14,
                  img: "https://i.postimg.cc/4dwSSmsr/loopTS.png",
                },
              });
              return group;
            } else if (this.loopType == 6) {
              const group = new G.Group({});
              group.addShape({
                type: "image",
                attrs: {
                  x: 0,
                  y: 12,
                  width: 14,
                  height: 14,
                  img: "https://i.postimg.cc/NfZPShvf/loopYDS.png",
                },
              });
              return group;
            } else if (this.loopType == 7) {
              const group = new G.Group({});
              group.addShape({
                type: "image",
                attrs: {
                  x: 0,
                  y: 12,
                  width: 14,
                  height: 14,
                  img: require("../../../static/pic/base.png"),
                },
              });
              return group;
            } else if (this.loopType == 8) {
              const group = new G.Group({});
              group.addShape({
                type: "image",
                attrs: {
                  x: 0,
                  y: 12,
                  width: 14,
                  height: 14,
                  img: require("../../../static/pic/cancel.png"),
                },
              });
              return group;
            } else if (item.eventType == "Correction Bolus") {
              const group = new G.Group({});
              group.addShape({
                type: "image",
                attrs: {
                  x: 0,
                  y: 12,
                  width: 14,
                  height: 14,
                  img: require("../../../static/pic/ydsns.png"),
                },
              });
              return group;
            } else if (item.eventType == "Carb Correction") {
              const group = new G.Group({});
              group.addShape({
                type: "image",
                attrs: {
                  x: 0,
                  y: 12,
                  width: 14,
                  height: 14,
                  img: require("../../../static/pic/tstr.png"),
                },
              });
              return group;
            } else if (item.eventType == "Temporary Override") {
              const group = new G.Group({});
              group.addShape({
                type: "image",
                attrs: {
                  x: 0,
                  y: 12,
                  width: 14,
                  height: 14,
                  img: require("../../../static/pic/wavens.png"),
                },
              });
              return group;
            } else if (item.eventType == "Suspend Pump") {
              const group = new G.Group({});
              group.addShape({
                type: "image",
                attrs: {
                  x: 0,
                  y: 12,
                  width: 14,
                  height: 14,
                  img: require("../../../static/pic/pump.png"),
                },
              });
              return group;
            } else {
              const group = new G.Group({});
              group.addShape({
                type: "image",
                attrs: {
                  x: 0,
                  y: 12,
                  width: 14,
                  height: 14,
                  img: require("../../../static/pic/other.png"),
                },
              });
              return group;
            }
          },
          labelLine: true,
        },
        tooltip: {
          showTitle: false,
          showCrosshairs: false,
          position: "top",
          customContent: (title, data) => {
            let loopType = title
              ? data[0].data.json
                ? JSON.parse(data[0].data.json).type
                : ""
              : "";
            let type = "";
            let eventType = title ? data[0].data.eventType : "";
            let res = title ? data[0].data : "";
            let parse = title
              ? data[0].data.json
                ? JSON.parse(data[0].data.json)
                : ""
              : "";
            if (title && data[0].data.type === "zx") {
              type = "指尖血";
              return `<div style="padding:4px;">
                      <div style="font-size:14px;font-weight: 700;margin-bottom:4px;">${type}</div>
                      <div style="margin-bottom:4px;"> ${title}</div>
                      <div style="margin-bottom:4px;"> 场景：${res.eventValue}</div>
                      <div style="margin-bottom:4px;">指血指数：${res.way}mmol/L</div>
                    </div>`;
            } else if (title && data[0].data.type === "kfy") {
              type = "口服药";
              return `<div style="padding:4px;">
                      <div style="font-size:14px;font-weight: 700;margin-bottom:4px;">${type}</div>
                      <div style="margin-bottom:4px;"> ${title}</div>
                      <div style="margin-bottom:4px;"> 用药时段：${res.scene}</div>
                      <div style="margin-bottom:4px;"> 药名：${res.way}</div>
                      <div style="margin-bottom:4px;"> 用量：${res.eventValue}</div>
                    </div>`;
            } else if (title && data[0].data.type === "ts") {
              type = "碳水";
              return `<div style="padding:4px;">
                      <div style="font-size:14px;font-weight: 700;margin-bottom:4px;">${type}</div>
                      <div style="margin-bottom:4px;"> ${title}</div>
                      <div style="margin-bottom:4px;"> ${res.eventValue}</div>
                    </div>`;
            } else if (title && data[0].data.type === "yds") {
              type = "胰岛素";
              return `<div style="padding:4px;">
                      <div style="font-size:14px;font-weight: 700;margin-bottom:4px;">${type}</div>
                      <div style="margin-bottom:4px;"> ${title}</div>
                      <div style="margin-bottom:4px;"> 注射时段：${res.scene}</div>
                      <div style="margin-bottom:4px;"> 胰岛素名称：${res.eventValue}</div>
                      <div style="margin-bottom:4px;"> 注射剂量：${res.way}U</div>
                    </div>`;
            } else if (title && data[0].data.type === "yd") {
              type = "运动";
              return `<div style="padding:4px;">
                      <div style="font-size:14px;font-weight: 700;margin-bottom:4px;">${type}</div>
                      <div style="margin-bottom:4px;"> ${title}</div>
                      <div style="margin-bottom:4px;"> 运动时常：${res.scene}h</div>
                      <div style="margin-bottom:4px;"> 消耗：${res.eventValue}kcal</div>
                      <div style="margin-bottom:4px;"> 备注：${res.way}</div>
                    </div>`;
            } else if (title && loopType == 1) {
              type = "关闭闭环";
            } else if (title && loopType == 2) {
              type = "开启闭环";
            } else if (title && loopType == 3) {
              type = "覆盖方波";
              return `<div style="padding:4px;">
                      <div style="font-size:14px;font-weight: 700;margin-bottom:4px;">${type}</div>
                      <div style="margin-bottom:4px;"> ${title}</div>
                      <div style="margin-bottom:4px;"> 持续时间：${parse.minutes}</div>
                      <div style="margin-bottom:4px;"> 原因：${parse.fugainame}</div>
                    </div>`;
            } else if (title && loopType == 4) {
              type = "取消方波";
            } else if (title && loopType == 5) {
              type = "添加碳水";
              return `<div style="padding:4px;">
                      <div style="font-size:14px;font-weight: 700;margin-bottom:4px;">${type}</div>
                      <div style="margin-bottom:4px;"> ${title}</div>
                      <div style="margin-bottom:4px;"> 总量：${parse.carbs}g</div>
                      <div style="margin-bottom:4px;"> 吸收时间：${parse.carbsHouers}</div>
                    </div>`;
            } else if (title && loopType == 6) {
              type = "大剂量";
              return `<div style="padding:4px;">
                      <div style="font-size:14px;font-weight: 700;margin-bottom:4px;">${type}</div>
                      <div style="margin-bottom:4px;"> ${title}</div>
                      <div style="margin-bottom:4px;"> 剂量：${parse.bolus}U</div>
                    </div>`;
            } else if (title && loopType == 7) {
              type = "临时基础率";
              return `<div style="padding:4px;">
                      <div style="font-size:14px;font-weight: 700;margin-bottom:4px;">${type}</div>
                      <div style="margin-bottom:4px;"> ${title}</div>
                      <div style="margin-bottom:4px;"> 临时基础率：${parse.perHour}U/h</div>
                      <div style="margin-bottom:4px;"> 持续时间：${parse.baseHouers}h</div>
                    </div>`;
            } else if (title && loopType == 8) {
              type = "取消临时";
            } else if (title && eventType == "Correction Bolus") {
              type = "胰岛素";
              return `<div style="padding:4px;">
                      <div style="font-size:14px;font-weight: 700;margin-bottom:4px;">${type}</div>
                      <div style="margin-bottom:4px;"> ${title}</div>
                      <div style="margin-bottom:4px;"> 剂量：${
                        res.insulin
                      }U</div>
                      <div style="margin-bottom:4px;"> 持续时间：${(
                        res.duration * 1
                      ).toFixed(2)}</div>
                      <div style="margin-bottom:4px;"> 名称：${
                        res.insulinType
                      }</div>
                    </div>`;
            } else if (title && eventType == "Carb Correction") {
              type = "碳水";
              return `<div style="padding:4px;">
                      <div style="font-size:14px;font-weight: 700;margin-bottom:4px;">${type}</div>
                      <div style="margin-bottom:4px;"> ${title}</div>
                      <div style="margin-bottom:4px;"> 克数：${
                        res.carbs ? res.carbs + "g" : "无"
                      }</div>
                      <div style="margin-bottom:4px;"> 类型：${
                        res.foodType ? res.foodType : "无"
                      }</div>
                    </div>`;
            } else if (title && eventType == "Temporary Override") {
              type = "覆盖方波";
              return `<div style="padding:4px;">
                      <div style="font-size:14px;font-weight: 700;margin-bottom:4px;">${type}</div>
                      <div style="margin-bottom:4px;"> ${title}</div>
                      <div style="margin-bottom:4px;"> 需求比例：${
                        res.insulinNeedsScaleFactor
                      }</div>
                      <div style="margin-bottom:4px;"> 持续时间：${(
                        res.duration * 1
                      ).toFixed(2)}分钟</div>
                      <div style="margin-bottom:4px;"> 原因：${res.reason}</div>
                    </div>`;
            } else if (title && eventType == "Suspend Pump") {
              type = "泵";
            } else {
              type = "其他";
            }
            return `<div style="padding:4px;">
                      <div style="font-size:14px;font-weight: 700;margin-bottom:4px;">${type}</div>
                      <div> ${title}</div>
                    </div>`;
          },
        },
        xAxis: {
          type: "time",
          visible: false,
          max: this.$moment(this.item.end).valueOf(),
          min: this.$moment(this.item.start).valueOf(),
          tickInterval: this.tickInterval,
          mask: "HH:mm", // 显示格式为小时:分钟
          tickMethod: (scale) => {
            const { min, max, tickCount } = scale;
            // const avg = (max - min) / tickCount;
            const ticks = [];
            for (let i = min; i <= max; i += this.tickInterval) {
              ticks.push(i);
            }
            return ticks;
          },
        },
        yAxis: {
          visible: false,
          grid: {
            visible: false,
          },
        },
        pointStyle: {
          fill: "transparent",
          stroke: "#fff",
        },
      });
      this.scatterPlot2.render();
    },

    getTempChart() {
      const data = this.tempBasal;
      this.tempPlot = new Line(this.item.title + "temp", {
        appendPadding: [0, 15, 0, 4],
        data,
        xField: "create_time",
        yField: "rate",
        stepType: "hv",
        tooltip: false,
        xAxis: {
          type: "time",
          visible: false, // x轴隐藏
          max: this.$moment(this.item.end).valueOf(),
          min: this.$moment(this.item.start).valueOf(),
          mask: "HH:mm", // 显示格式为小时:分钟
          tickMethod: (scale) => {
            const { min, max, tickCount } = scale;
            // const avg = (max - min) / tickCount;
            const ticks = [];
            for (let i = min; i <= max; i += this.tickInterval) {
              ticks.push(i);
            }
            return ticks;
          },
        },
        yAxis: {
          visible: false, // y轴隐藏
          max: this.maxRate,
          min: 0,
          grid: {
            visible: false, // 栅格隐藏
          },
        },
        lineStyle: {
          lineWidth: 1,
        },
        // area: {
        //   fill: true,
        //   style: {
        //     fillOpacity: 0.55,
        //   },
        // },
      });
      this.tempPlot.render();
    },
  },

  computed: {
    maxTime() {
      const nextTime = this.$moment()
        .add(1, "hours")
        .format("YYYY-MM-DD HH:00:00");
      return new Date(nextTime).getTime();
    },
    minTime3() {
      const min = this.$moment(
        this.$moment(this.$moment().subtract(2, "hours")).startOf("hour")
      ).format("YYYY-MM-DD HH:00:00");
      return new Date(min).getTime();
    },
    minTime6() {
      const min = this.$moment(
        this.$moment(this.$moment().subtract(5, "hours")).startOf("hour")
      ).format("YYYY-MM-DD HH:00:00");
      return new Date(min).getTime();
    },
    minTime12() {
      const min = this.$moment(
        this.$moment(this.$moment().subtract(11, "hours")).startOf("hour")
      ).format("YYYY-MM-DD HH:00:00");
      return new Date(min).getTime();
    },
    minTime24() {
      const min = this.$moment(
        this.$moment(this.$moment().subtract(23, "hours")).startOf("hour")
      ).format("YYYY-MM-DD HH:00:00");
      return new Date(min).getTime();
    },
  },

  watch: {
    item: {
      handler: function (newVal, oldVal) {
        if (oldVal) {
          this.$nextTick(() => {
            this.line.destroy();
            this.scatterPlot2.destroy();
            this.tempPlot.destroy();
            this.drawChart();
            this.getChart2();
            this.getTempChart();
          });
        }
      },
    },
  },
};
</script>

<style lang="less" scoped>
.container {
  display: flex;
}
.loopBox {
  height: 360px;
  margin-left: 5px;
}
.loopTip {
  width: 100px;
  height: 30px;
  line-height: 30px;
  border-radius: 15px;
  text-align: center;
  font-size: 14px;
  color: #fff;
  margin-top: 10px;
}
.loopTitle {
  font-weight: 700;
  height: 100px;
  line-height: 100px;
}
.sugarTitle {
  font-weight: 700;
  height: 45px;
  line-height: 45px;
  font-size: 12px;
}
.loopUnit {
  float: right;
  margin-right: 30px;
}
#miniChart {
  position: relative;
}
/* 事件标记样式 */
.marker {
  position: absolute;
  top: 0.5rem;
  width: 100px;
  z-index: 99999;
}
/* 梯形图样式 */
.temp {
  position: absolute;
  bottom: 10px;
  z-index: 9999999;
}
</style>
