<template>
  <div
    id="homePages"
    style="background: #f8f8f8"
    @touchstart="handleTouchStart"
    @touchmove="handleTouchMove"
  >
    <router-view></router-view>

    <van-tabbar
      route
      v-if="navShow"
      active-color="#7accff"
      v-show="!$store.state.isLandscape"
    >
      <van-tabbar-item replace to="/BloodSugar" icon="home-o">
        <span>血糖</span>
        <template #icon="props">
          <img :src="props.active ? blood : bloodU" />
        </template>
      </van-tabbar-item>
      <van-tabbar-item replace to="/ReportCenter" icon="description">
        <span>报告</span>
        <template #icon="props">
          <img :src="props.active ? report : reportU" />
        </template>
      </van-tabbar-item>
      <van-tabbar-item replace to="/loopHandle" icon="search">
        <span>闭环</span>
        <template #icon="props">
          <img :src="props.active ? loopU : loop" />
        </template>
      </van-tabbar-item>
      <van-tabbar-item replace to="/MineView" icon="contact">
        <span>我的</span>
        <template #icon="props">
          <img :src="props.active ? mine : mineU" />
        </template>
      </van-tabbar-item>
    </van-tabbar>
    <van-dialog
      class="hintDialog"
      v-model="showHint"
      title="提示"
      show-cancel-button
    >
      <p>您的闭环尚未接入到糖仁云</p>
      <br />
      <p>请接入后再试</p>
    </van-dialog>
  </div>
</template>
<script>
// import { Toast } from 'vant'
import MoreDialog from "../moreDialog/index.vue";
import blood from "../../static/pic/blood.png";
import report from "../../static/pic/report.png";
import recover from "../../static/pic/recover.png";
import mine from "../../static/pic/mine.png";
import bloodU from "../../static/pic/bloodU.png";
import reportU from "../../static/pic/reportU.png";
import recoverU from "../../static/pic/recoverU.png";
import mineU from "../../static/pic/mineU.png";
import shop from "../../static/pic/shop.png";
import shopU from "../../static/pic/shopU.png";
import loop from "../../static/pic/loop.png";
import loopU from "../../static/pic/loopU.png";

import { getLoopOption } from "@/api/relationship";
export default {
  name: "homePages",
  components: {
    MoreDialog,
  },
  data() {
    return {
      navShow: true,
      showCGMDia: false,
      showIframe: false,
      showGJEmpower: false,
      hadBindCGM: false,
      hadLogin: false,
      showDoalog: false,
      bindingCGM: {},
      noCGM: true,
      blood: blood,
      report: report,
      recover: recover,
      mine: mine,
      bloodU: bloodU,
      reportU: reportU,
      recoverU: recoverU,
      mineU: mineU,
      shop: shop,
      shopU: shopU,
      loop: loop,
      loopU: loopU,
      selectedNavigation: 0,
      showMoreDialog: false,
      startX: "",
      startY: "",
      endX: "",
      endY: "",
      slideX: "",
      slideY: "",
      startTime: "",
      endTime: "",
      slideTime: "",
      showHint: false,
    };
  },
  watch: {
    $route: {
      handler(val, oldValue) {
        if (val.query.index == 0) {
          this.selectedNavigation = 0;
        }
        if (
          this.$route.path == "/BloodSugar" ||
          this.$route.path == "/ReportCenter" ||
          this.$route.path == "/MineView" ||
          this.$route.path == "/loopHandle"
        ) {
          this.navShow = true;
        } else {
          this.navShow = false;
        }
      },
      immediate: true, //先执行一遍
    },
  },
  mounted() {
    document.addEventListener("click", (e) => {
      const moreBar = document.querySelector(".moreBar");
      const moreNav = document.querySelector(".moreNav");
      if (moreBar && moreNav) {
        if (!moreBar.contains(e.target) && !moreNav.contains(e.target)) {
          this.showMoreDialog = false;
        }
      }
    });
    this.checkLogin();

    window.transferWait = this.transferWait;
    window.transferWaitAndroid = this.transferWaitAndroid;
  },

  methods: {
    handleTouchStart(event) {
      if (event.touches.length === 1) {
        this.startX = event.touches[0].clientX;
        this.startY = event.touches[0].clientY;
      }
    },
    handleTouchMove(event) {
      if (event.touches.length === 1) {
        const deltaX = event.touches[0].clientX - this.startX;
        const deltaY = event.touches[0].clientY - this.startY;

        if (Math.abs(deltaX) > Math.abs(deltaY)) {
          event.preventDefault();
        }
      }
    },
    // touchStart(e) {
    //   this.startX = e.touches[0].pageX;
    //   this.startY = e.touches[0].clientY;
    //   this.startTime = new Date().getTime();
    // },
    // touchEnd(e) {
    //   console.log(this.$route.path, "route.path");
    //   this.endX = e.changedTouches[0].pageX;
    //   this.endY = e.changedTouches[0].clientY;
    //   this.endTime = new Date().getTime();
    //   this.slideTime = this.endTime - this.startTime;
    //   this.slideX = this.endX - this.startX;
    //   this.slideY = Math.abs(this.endY - this.startY);
    //   if (
    //     this.slideX > 10 &&
    //     this.slideTime < 500 &&
    //     this.slideX > this.slideY &&
    //     this.$route.path != "/BloodSugar" &&
    //     this.$route.path != "/ReportCenter" &&
    //     this.$route.path != "/RecordDetail" &&
    //     this.$route.path != "/MineView" &&
    //     this.$route.path != "/login" &&
    //     this.$route.path != "/passwordLogin" &&
    //     this.$route.path != "/baiban" &&
    //     this.$route.path != "/waitingB" &&
    //     this.$route.path != "/waitingA" &&
    //     this.$route.path != "/nsPage"
    //   ) {
    //     this.$router.go(-1);
    //   }
    // },
    //接收共享音频
    transferWait(val) {
      this.$router.push({
        path: "waitingB",
        query: {
          baibanId: val.parameter.data,
          name: val.parameter.name,
          img: val.parameter.img,
        },
      });
    },
    transferWaitAndroid(info) {
      const val = JSON.parse(JSON.stringify(info));
      // if (val.callTime >= 30000) {
      //   this.$router.push("/BloodSugar");
      // } else {
      this.$router.push({
        path: "waitingB",
        query: {
          baibanId: val.data,
          name: val.name,
          img: val.img,
          callTime: val.callTime,
        },
      });
      // }
      return "确定";
    },
    openGJEmpower() {
      this.showIframe = true;
      this.showCGMDia = false;
      this.showGJEmpower = true;
    },
    closeGJEmpower() {
      this.showIframe = false;
      this.showGJEmpower = false;
    },
    chageSelect(i) {
      const date = localStorage.getItem("clickFBI");
      if (!date) {
        localStorage.setItem("clickFBI", new Date());
      } else {
        if (new Date() - date <= 2000) {
          localStorage.setItem("clickFBI", new Date());
          return;
        } else {
          localStorage.setItem("clickFBI", new Date());
        }
      }
      if (i !== 2) {
        this.selectedNavigation = i;
        if (i == 3) {
          const uid = JSON.parse(localStorage.getItem("appuser")).id;
          getLoopOption({ uid: uid }).then((data) => {
            console.log(data);
            if (data && data.data) {
              localStorage.setItem("loopOptions", JSON.stringify(data.data));
              this.$router.push("/loopHandle");
            } else {
              this.showHint = true;
            }
          });
        }
      } else {
        this.showMoreDialog = true;
        this.showDoalog = true;
      }
    },
    closeMoreBar() {
      this.showMoreDialog = false;
      this.showDoalog = false;
    },
    checkLogin() {
      const loginStatus = localStorage.getItem("loginStatus");
      if (loginStatus && loginStatus === "true") {
        this.hadLogin = true;
      } else {
        console.log(this.$route.path, "path");
        setTimeout(() => {
          if (this.$route.path == "/addPatient") {
            console.log("不走了");
          } else {
            this.$router.push("/login");
          }
        }, 300);
      }
    },
    openCGMDia() {
      this.showCGMDia = true;
    },
    colseCGMView() {
      this.showCGMDia = false;
    },
    selectThecGM(bindingCGM) {
      this.bindingCGM = bindingCGM;
      this.noCGM = false;
      this.hadBindCGM = true;
    },
    editBindingCGM(device) {
      this.bindingCGM = device;
    },
  },
};
</script>
<style scoped>
#navigationBar {
  height: 6rem;
  background: #fff;
  position: fixed;
  z-index: 9;
  bottom: 0;
  width: 100vw;
  z-index: 99;
  border-top: 1px #cecece solid;
}
.navigationItem {
  display: block;
}
#homePage {
  position: relative;
}
.barItem {
  height: 91vh;
  overflow-y: auto;
}
.van-tabbar {
  height: 60px;
}
::v-deep .van-tabbar-item__icon {
  margin-bottom: 8px !important;
}
</style>
