import Vue from "vue";
import VueRouter from "vue-router";
// import HomeView from '../views/HomeView.vue'
import PatientsView from "../pages/patients/index.vue";
import DoctorHome from "../pages/homePage/doctorHome.vue";
import MyChart from "../pages/bloodSugar/component/newChart.vue";
import QRCode from "../pages/components/QRCode.vue";
import SmartTree from "../pages/components/SmartTree.vue";
import CGMDetails from "../pages/bloodSugar/component/CGMDetails.vue";
import PersonnelManagement from "../pages/personnelManagement/index.vue";
import HospitalList from "../pages/personnelManagement/hospitalList.vue";
import alarmCFG from "../pages/bloodSugar/component/alarmCFG.vue";
import wangEmitter from "../pages/bloodSugar/component/wangEmitter.vue";
import departmentList from "../pages/personnelManagement/departmentList.vue";
import departmentView from "../pages/personnelManagement/components/departmentView.vue";
import adminMain from "../pages/admin/adminMain.vue";
import passwordLogin from "../pages/login/passwordLogin.vue";
import register from "../pages/login/register.vue";
import login from "../pages/login/index.vue";
import BloodSugar from "../pages/bloodSugar/index.vue";
import ReportCenter from "../pages/reportCenter/index.vue";
import FollowReportCenter from "../pages/reportCenter/followIndex.vue";
import kfyView from "../pages/recordDetail/recordView/kfyView.vue";
import tsView from "../pages/recordDetail/recordView/tsView.vue";
import ydsView from "../pages/recordDetail/recordView/ydsView.vue";
import ydView from "../pages/recordDetail/recordView/ydView.vue";
import zxView from "../pages/recordDetail/recordView/zxView.vue";
import RecordDetail from "../pages/recordDetail/index.vue";
import RecordAdd from "../pages/recordDetail/recordAdd.vue";
import NoFollow from "../pages/recordDetail/noFollow.vue";
import FollowView from "../pages/recordDetail/followView.vue";
import MineView from "../pages/mineView/index.vue";
import MineEdit from "../pages/mineView/component/editDialog.vue";
import addRelationshipByCode from "../pages/personnelManagement/addRelationshipByCode.vue";
import GJEmpower from "../pages/homePage/component/GJEmpower.vue";
import SNEmpower from "../pages/homePage/component/SNEmpower.vue";
import userTable from "../pages/admin/dataTable/userTable.vue";
import alarmCFG0 from "../pages/mineView/component/alarmCFG0.vue";
// 医生认证和推广员认证模块
import authentication from "../pages/mineView/component/authentication.vue";
import promoter from "../pages/mineView/component/promoter.vue";
import hospitalAdd from "../pages/mineView/component/hospitalAdd.vue";
import hospitalNo from "../pages/mineView/component/hospitalNo.vue";
import hospitalView from "../pages/mineView/component/hospitalView.vue";
import doctorAdd from "../pages/mineView/component/doctorAdd.vue";
// 关注模块
import myHospital from "../pages/recordDetail/component/myHospital.vue";
import myPatients from "../pages/recordDetail/component/myPatients.vue";
// loop闭环模块
import loopHandle from "../pages/mineView/component/loopHandle.vue";
import loopHandleList from "../pages/mineView/component/loopHandleList.vue";
import loopDetails from "../pages/bloodSugar/component/loopDetails.vue";
// test
import test from '@/pages/bloodSugar/test.vue'
Vue.use(VueRouter);

//获取原型对象上的push函数
const originalPush = VueRouter.prototype.push;
//修改原型对象中的push方法
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};

const routes = [
  {
    path: "/test",
    name: "test",
    component: test,
  },
  {
    path: "/",
    redirect: "/BloodSugar",
  },
  {
    path: "/userTable",
    name: "userTable",
    component: userTable,
  },
  {
    path: "/BloodSugar",
    name: "BloodSugar",
    component: BloodSugar,
  },
  {
    path: "/BloodSugar/GJEmpower",
    name: "GJEmpower",
    component: GJEmpower,
  },
  {
    path: "/BloodSugar/SNEmpower",
    name: "SNEmpower",
    component: SNEmpower,
  },
  {
    path: "/MineView",
    name: "MineView",
    component: MineView,
  },
  {
    path: '/mineEdit',
    name: 'mineEdit',
    component: MineEdit,
  },
  {
    path: "/RecordDetail",
    name: "RecordDetail",
    component: RecordDetail,
  },
  // 添加关注页
  {
    path: "/RecordAdd",
    name: "RecordAdd",
    component: RecordAdd,
  },
  // 未关注页
  {
    path: "/NoFollow",
    name: "NoFollow",
    component: NoFollow,
  },
  // 未关注页
  {
    path: "/FollowView",
    name: "FollowView",
    component: FollowView,
  },
  // 白板页面
  {
    path: "/baiban",
    name: "baiban",
    component: () => import("../pages/recordDetail/baiban.vue"),
  },
  // 血糖指导等待页面--医生
  {
    path: "/waitingA",
    name: "waitingA",
    component: () => import("../pages/recordDetail/waitingA.vue"),
  },
  // 血糖指导等待页面--患者
  {
    path: "/waitingB",
    name: "waitingB",
    component: () => import("../pages/recordDetail/waitingB.vue"),
  },
  // 登陆账号报告页
  {
    path: "/ReportCenter",
    name: "ReportCenter",
    component: ReportCenter,
  },
  // 关注用户报告页
  {
    path: "/FollowReportCenter",
    name: "FollowReportCenter",
    component: FollowReportCenter,
  },
  {
    path: "/ReportCenter/kfyView",
    name: "kfyView",
    component: kfyView,
  },
  {
    path: "/ReportCenter/tsView",
    name: "tsView",
    component: tsView,
  },
  {
    path: "/ReportCenter/ydsView",
    name: "ydsView",
    component: ydsView,
  },
  {
    path: "/ReportCenter/ydView",
    name: "ydView",
    component: ydView,
  },
  {
    path: "/ReportCenter/zxView",
    name: "zxView",
    component: zxView,
  },
  {
    path: "/MineView/adminMain",
    name: "adminMain",
    component: adminMain,
  },
  {
    path: "/passwordLogin",
    name: "passwordLogin",
    component: passwordLogin,
  },
  // 注册
  {
    path: "/register",
    name: "register",
    component: register,
  },
  // 微信扫描医院码跳转此页面加入医院，然后跳到硅基授权页面
  {
    path: "/addPatient",
    name: "addPatient",
    component: () => import("@/pages/patients/addPatient.vue"),
  },
  {
    path: "/login",
    name: "login",
    component: login,
  },
  {
    path: "/departmentList",
    name: "departmentList",
    component: departmentList,
  },
  {
    path: "/departmentView",
    name: "departmentView",
    component: departmentView,
  },
  {
    path: "/QRCode",
    name: "QRCode",
    component: QRCode,
  },
  {
    path: "/MineView/PersonnelManagement",
    name: "PersonnelManagement",
    component: PersonnelManagement,
  },
  {
    path: "/hospitalList",
    name: "HospitalList",
    component: HospitalList,
  },
  {
    path: "/CGMDetails",
    name: "CGMDetails",
    component: CGMDetails,
  },
  {
    path: "/SmartTree",
    name: "SmartTree",
    component: SmartTree,
  },
  {
    path: "/BloodSugar/alarmCFG",
    name: "alarmCFG",
    component: alarmCFG,
  },
  {
    path: "/MineView/alarmCFG0",
    name: "alarmCFG0",
    component: alarmCFG0,
  },
  {
    path: "/BloodSugar/wangEmitter",
    name: "wangEmitter",
    component: wangEmitter,
  },
  {
    path: "/about",
    name: "about",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/AboutView.vue"),
  },
  {
    path: "/PatientsView",
    name: "PatientsView",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: PatientsView,
  },
  {
    path: "/MyChart",
    name: "MyChart",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: MyChart,
  },
  {
    path: "/doctorHome",
    name: "DoctorHome",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: DoctorHome,
  },
  {
    path: "/addRelationshipByCode",
    name: "addRelationshipByCode",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: addRelationshipByCode,
  },
  // 医生认证
  {
    path: "/authentication",
    name: "authentication",
    component: authentication,
  },
  // 医生认证
  {
    path: "/promoter",
    name: "promoter",
    component: promoter,
  },
  // 添加医院
  {
    path: "/hospitalAdd",
    name: "hospitalAdd",
    component: hospitalAdd,
  },
  // 待审核医院
  {
    path: "/hospitalNo",
    name: "hospitalNo",
    component: hospitalNo,
  },
  // 医院详情
  {
    path: "/hospitalView",
    name: "hospitalView",
    component: hospitalView,
  },
  // 添加医生
  {
    path: "/doctorAdd",
    name: "doctorAdd",
    component: doctorAdd,
  },
  // 关注页面我的医院
  {
    path: "/myHospital",
    name: "myHospital",
    component: myHospital,
  },
  // 我的医院下患者列表
  {
    path: "/myPatients",
    name: "myPatients",
    component: myPatients,
  },
  // loop闭环操作
  {
    path: "/loopHandle",
    name: "loopHandle",
    component: loopHandle,
  },
  // loop操作列表
  {
    path: "/loopHandleList",
    name: "loopHandleList",
    component: loopHandleList,
  },
  // loop详情信息
  {
    path: "/loopDetails",
    name: "loopDetails",
    component: loopDetails,
  },
  {
    path: "/AssessReport",
    name: "病历报告",
    component: () => import("@/pages/assessReport/index"),
    hidden: true,
  },
  // 扫码添加患者信息页面
  {
    path: "/bindingInfo",
    name: "bindingInfo",
    component: () => import("@/pages/patients/bindingInfo.vue"),
  },
  // 患者病历页面
  {
    path: "/patientCases",
    name: "patientCases",
    component: () => import("@/pages/patients/patientCases.vue"),
  },
  // 医生扫描二维码加入医院
  {
    path: "/joinHospital",
    name: "joinHospital",
    component: () => import("@/pages/mineView/component/joinHospital.vue"),
  },
  // 硅基授权码页面
  {
    path: "/GJcode",
    name: "GJcode",
    component: () => import("@/pages/mineView/component/GJcode.vue"),
  },
  // 闭环血糖页面
  {
    path: "/nsPage",
    name: "nsPage",
    component: () => import("@/pages/nsPages/index.vue"),
  },
  // 商城
  {
    path: "/shop",
    name: "shop",
    component: () => import("@/pages/shop.vue"),
  },
  // 编辑头像
  {
    path: "/editAva",
    name: "editAva",
    component: () => import("@/pages/mineView/component/editAva.vue"),
  },
];

const router = new VueRouter({
  routes,
  // 保证路由跳转页面之后，都保持在页面的最顶端
  scrollBehavior(to, form, savedPosition) {
    return { x: 0, y: 0 };
  },
});

export default router;
