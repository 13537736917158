import { render, staticRenderFns } from "./zxView.vue?vue&type=template&id=3fb3db4d&scoped=true"
import script from "./zxView.vue?vue&type=script&lang=js"
export * from "./zxView.vue?vue&type=script&lang=js"
import style0 from "./zxView.vue?vue&type=style&index=0&id=3fb3db4d&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3fb3db4d",
  null
  
)

export default component.exports