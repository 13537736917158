<template>
  <div class="box" ref="box" :class="isFullScreen ? 'shuiping' : 'chuizhi'">
    <div ref="imgBox" class="imgBox">
      <!-- 标题 -->
      <div class="common-title">近{{ time }}小时血糖曲线</div>

      <span class="unit">mmol/L</span>

      <!-- 散点图 -->
      <div
        v-show="chartData"
        id="container1"
        ref="container"
        style="width: 100%; height: 490px"
      ></div>
      <!-- 临时基础率梯形图 -->
      <div id="temp" ref="temp" style="width: 100%; height: 13.1rem"></div>
      <!-- 时间切换 -->
      <div style="padding: 1rem 0; text-align: center">
        <el-radio-group
          :disabled="flag"
          v-model="chartTime"
          @change="getChartTime()"
        >
          <el-radio :label="0.875">3H</el-radio>
          <el-radio :label="0.75">6H</el-radio>
          <el-radio :label="0.5">12H</el-radio>
          <el-radio :label="0">24H</el-radio>
        </el-radio-group>
      </div>
    </div>
  </div>
</template>
<script>
import { debounce } from "lodash";
import { Line, G2, Scatter } from "@antv/g2plot";
import {
  getSugerbyId,
  getEvent,
  getLoopHandleList,
  loopTreatments,
} from "@/api/userServices";
import { loopHistory } from "@/api/relationship";
import yds from "@/static/pic/ydsns.png";
const G = G2.getEngine("canvas");
export default {
  props: {
    userId: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      isFullScreen: false,
      minTime: "",
      tickInterval: "",
      scatterPlot: null,
      scatterPlot2: null,
      tempPlot: null,

      eventArr: [], // 事件列表
      chartData: [],
      tempBasal: [], // 临时基础率

      time: 3, // 时间段选择
      chartTime: 0.875,
      timer: null, // 5分钟一次的定时器
      timerNum: 0,
      startEventTime: "",

      CorrectionBolus: [], // 胰岛素
      SuspendPump: [], // 泵
      CarbCorrection: [], // 碳水
      TemporaryOverride: [], // 覆盖方波
      loopType: "",
      maxRate: "",
      flag: false,

      sliderEnd: 1,
      sliderStart: 0,
      startX: "",
      endX: "",
      moveX: "",
      boxWidth: "",
      moveRate: "",
      downLeft: "",
      downRight: "",
      isHaveSugar: true,
    };
  },
  created() {
    setTimeout(() => {
      this.getTempChart();
    }, 300);
    this.getDateArray();
  },
  mounted() {
    this.sliderStart = this.chartTime;
    this.boxWidth = this.$refs.container.offsetWidth - 80;
    this.$refs.container.addEventListener(
      "touchstart",
      (event) => {
        this.moveRate = 0;
        this.isDown = true;
        this.startX = event.touches[0].pageX;
        this.downLeft = this.sliderStart;
        this.downRight = this.sliderEnd;
      },
      true
    );
    document.body.addEventListener(
      "touchend",
      (event) => {
        this.isDown = false;
      },
      true
    );
    this.timer = setInterval(() => {
      this.timerNum++;
    }, 4 * 60 * 1000);
    this.$once("hook:beforeDestroy", () => {
      // 页面关闭
      clearInterval(this.timer); // 停止
    });
  },
  methods: {
    // 血糖事件集合数组按照事件排序
    sortTime(obj) {
      obj.sort((a, b) => {
        let t1 = new Date(Date.parse(a.create_time.replace(/-/g, "/")));
        let t2 = new Date(Date.parse(b.create_time.replace(/-/g, "/")));
        return t1.getTime() - t2.getTime();
      });
      return obj;
    },
    // 拖动slider回调
    handleMove: debounce(function (e) {
      let params = {
        uid: JSON.parse(localStorage.getItem("appuser")).id,
        create_time:
          e.view.geometries[0].data[e.view.geometries[0].data.length - 1]
            .create_time,
      };
      loopHistory(params).then((res) => {
        this.$emit("loopInfo", res);
      });
      this.$nextTick(() => {
        this.endX = e.gEvent.clientX;
        this.moveX = this.endX - this.startX;
        console.log(this.endX, this.moveX);
        this.moveRate = this.moveX / this.boxWidth;
        if (this.sliderEnd >= 1 && this.moveRate > 0) {
          return;
        } else if (this.sliderStart <= 0 && this.moveRate < 0) {
          return;
        }
        if (this.moveRate > 0 && this.moveRate > 1 - this.downRight) {
          this.sliderEnd = 1;
          this.sliderStart = this.sliderStart + (1 - this.downRight);
          this.tempPlot.destroy();
          this.getTempChart();
          return;
        }
        if (this.moveRate < 0 && Math.abs(this.moveRate) > this.downLeft) {
          this.sliderEnd = this.sliderEnd - this.downLeft;
          this.sliderStart = 0;
          this.tempPlot.destroy();
          this.getTempChart();
          return;
        }
        this.sliderStart = this.sliderStart + this.moveRate;
        this.sliderEnd = this.sliderEnd + this.moveRate;
        this.tempPlot.destroy();
        this.getTempChart();
      });
    }, 300),
    getChartTime() {
      this.sliderStart = this.chartTime;
      this.sliderEnd = 1;
      this.$nextTick(() => {
        this.scatterPlot.destroy();
        this.tempPlot.destroy();
        this.getChart();
        this.getTempChart();
        if (this.chartTime == 0.875) {
          this.time = 3;
        } else if (this.chartTime == 0.75) {
          this.time = 6;
        } else if (this.chartTime == 0.5) {
          this.time = 12;
        } else if (this.chartTime == 0) {
          this.time = 24;
        }
      });
    },
    // 获取临时基础率
    getTreat() {
      this.tempBasal = [];
      const uid = JSON.parse(localStorage.getItem("appuser")).id;
      const params = {
        uid: uid,
        end: this.$moment().format("YYYY-MM-DD HH:mm:ss"),
        start: this.$moment()
          .subtract(23, "hours")
          .format("YYYY-MM-DD HH:mm:ss"),
      };
      loopTreatments(params).then(({ data }) => {
        // 把所有治疗数据分组
        data.treatments.forEach((item) => {
          // 临时基础率分组
          if (item.eventType === "Temp Basal") {
            item.rate = item.rate * 1;
            this.tempBasal.push(item);
          }
          // 胰岛素
          if (item.eventType === "Correction Bolus") {
            this.CorrectionBolus.push(item);
          }
          // 泵分组
          if (item.eventType === "Suspend Pump") {
            this.SuspendPump.push(item);
          }
          // 碳水分组
          if (item.eventType === "Carb Correction") {
            this.CarbCorrection.push(item);
          }
          // 覆盖方波分组
          if (item.eventType === "Temporary Override") {
            this.TemporaryOverride.push(item);
          }
        });
        // 获取基础率里面的最大值
        let rateArr = [];
        this.tempBasal.forEach((item) => {
          rateArr.push(item.rate);
        });
        this.maxRate = Math.max(...rateArr);
      });
    },

    // 获取所有事件
    getAllEvent() {
      this.eventArr = [];
      // 获取糖仁云事件
      // const params = {
      //   id: JSON.parse(localStorage.getItem('appuser')).id,
      //   end: this.$moment().format('YYYY-MM-DD HH:mm:ss'),
      //   start: this.$moment()
      //     .subtract(23, 'hours')
      //     .format('YYYY-MM-DD HH:mm:ss'),
      // }
      // getEvent(params).then(({ data }) => {
      //   console.log(data, '111111111111');
      //   data.data.forEach((item) => {
      //     this.eventArr.push(item)
      //   })
      // })

      // 获取远程操作事件
      // const id = this.$route.query.patient_id
      //   ? this.$route.query.patient_id
      //   : this.$route.query.id
      // getLoopHandleList(id).then(({ data }) => {
      //   data.data.forEach((item) => {
      //     if (item.status == 1) {
      //       item.v = 0
      //       this.eventArr.push(item)
      //     }
      //   })
      // 获取 ns 事件
      const nsParams = {
        uid: JSON.parse(localStorage.getItem("appuser")).id,
        end: this.$moment().format("YYYY-MM-DD HH:mm:ss"),
        start: this.$moment()
          .subtract(23, "hours")
          .format("YYYY-MM-DD HH:mm:ss"),
      };
      loopTreatments(nsParams).then(({ data }) => {
        data.treatments.forEach((item) => {
          if (item.eventType === "Temp Basal") {
          }
          // 胰岛素
          else if (item.eventType === "Correction Bolus") {
            item.createTime = item.create_time;
            item.v = 0;
            item.type = item.eventType;
            this.eventArr.push(item); // 添加ns事件---胰岛素
          }
          // 碳水分组
          else if (item.eventType === "Carb Correction") {
            item.createTime = item.create_time;
            item.v = -1;
            item.type = item.eventType;
            this.eventArr.push(item); // 添加ns事件---碳水
          }
          // 覆盖方波分组
          else if (item.eventType === "Temporary Override") {
            item.createTime = item.create_time;
            item.v = -2;
            item.type = item.eventType;
            this.eventArr.push(item); // 添加ns事件---覆盖方波
          }
          // 泵分组
          else if (item.eventType === "Suspend Pump") {
            item.createTime = item.create_time;
            item.v = -2;
            item.type = item.eventType;
            this.eventArr.push(item);
          }
          // 其他
          else {
            item.createTime = item.create_time;
            item.v = -2;
            item.type = item.eventType;
            this.eventArr.push(item);
          }
        });
        this.eventArr.forEach((event) => {
          event.create_time = event.createTime;
          event.type = event.type;
          event.tooltip_time = event.createTime.substring(5, 16);
          event.v = 1;
          this.chartData.push(event);
        });
        this.sortTime(this.chartData);
        let v2 = "";
        let v1 = "";
        this.chartData.forEach((item, index) => {
          if (item.type != "sugar") {
            if (item.type == "Temporary Override") {
              item.v = 2.5;
            } else {
              for (let i = index - 1; i >= 0; i--) {
                if (this.chartData[i].type == "sugar") {
                  v1 = this.chartData[i].v;
                  break;
                }
              }
              for (let i = index + 1; i <= this.chartData.length - 1; i++) {
                if (this.chartData[i].type == "sugar") {
                  v2 = this.chartData[i].v;
                  break;
                } else if (!this.chartData[i]) {
                  v2 = v1;
                  break;
                }
              }
              item.v = (v1 + v2) / 2;
            }
          }
        });
        this.getChart();
      });
      // })
    },

    getChart() {
      this.flag = false;
      const data = this.chartData;
      this.scatterPlot = new Scatter("container1", {
        appendPadding: [30, 15, 10, 5],
        data,
        // reflect: 'y',
        xField: "tooltip_time",
        yField: "v",

        legend: false,
        // seriesField: 'tooltip_time',
        color: ["#5b8ff9"],
        fill: "#5b8ff9",
        size: this.chartTime == 0 || 0.5 ? 3 : 4,
        shape: "circle",
        slider: {
          start: this.chartTime,
          end: 1,
          height: 50,
          textStyle: {
            fillOpacity: 0,
          },
          trendCfg: {
            lineStyle: {
              stroke: "#5b8ff9",
              lineWidth: 2,
              lineDash: [4, 5],
              strokeOpacity: 0.7,
              shadowColor: "#5b8ff9",
              shadowBlur: 10,
              shadowOffsetX: 5,
              shadowOffsetY: 5,
              cursor: "pointer",
            },
          },
          handlerStyle: {
            width: 0,
            height: 0,
            opacity: 0,
          },
        },
        pointStyle: (x, y, colorField) => {
          if (x.v > 10) {
            return {
              fill: "#F4664A",
              opacity: 0.8,
            };
          } else if (x.v < 3.9) {
            return {
              fill: "#ffaa29",
              opacity: 0.8,
            };
          } else {
            return {
              stroke: "#f0f0f0",
            };
          }
        },

        // 口服药kfy
        // https://i.postimg.cc/nL6nCZ4R/kfy.png
        // https://i.postimg.cc/3J3mzfKy/image.png
        // 碳水ts
        // https://i.postimg.cc/yNTwzHf3/ts.png
        // https://i.postimg.cc/sxTXkccS/01.png
        // 胰岛素yds
        // https://i.postimg.cc/fTBCZbZN/yds.png
        // https://i.postimg.cc/9XS7cMF3/1.jpg
        // 运动yd
        // https://i.postimg.cc/SNv9DYkJ/yd.png
        // https://i.postimg.cc/rwxTWjrJ/image.png
        // 指尖血zs
        // https://i.postimg.cc/bJmrr4vs/zx.png
        // https://i.postimg.cc/0yRR8SBJ/wlj-xcx-sl-ico-liuliang01.png

        label: {
          offsetY: 0,
          content: (item) => {
            this.loopType = item.json ? JSON.parse(item.json).type : "";
            if (item.type == "kfy") {
              const group = new G.Group({});
              group.addShape({
                type: "image",
                attrs: {
                  x: 0,
                  y: 12,
                  width: 14,
                  height: 14,
                  img: require("@/static/pic/kfytr.png"),
                },
              });
              // group.addShape({
              //   type: 'text',
              //   attrs: {
              //     x: 6,
              //     y: 2,
              //     text: item.type,
              //     textAlign: 'center',
              //     textBaseline: 'top',
              //     fill: '#000'
              //   }
              // })
              return group;
            } else if (item.type == "ts") {
              const group = new G.Group({});
              group.addShape({
                type: "image",
                attrs: {
                  x: 0,
                  y: 12,
                  width: 14,
                  height: 14,
                  img: "https://i.postimg.cc/sxTXkccS/01.png",
                },
              });
              return group;
            } else if (item.type == "yds") {
              const group = new G.Group({});
              group.addShape({
                type: "image",
                attrs: {
                  x: 0,
                  y: 12,
                  width: 14,
                  height: 14,
                  img: require("@/static/pic/ydstr.png"),
                },
              });
              return group;
            } else if (item.type == "yd") {
              const group = new G.Group({});
              group.addShape({
                type: "image",
                attrs: {
                  x: 0,
                  y: 12,
                  width: 14,
                  height: 14,
                  img: "https://i.postimg.cc/rwxTWjrJ/image.png",
                },
              });
              return group;
            } else if (item.type == "zx") {
              const group = new G.Group({});
              group.addShape({
                type: "image",
                attrs: {
                  x: 0,
                  y: 12,
                  width: 14,
                  height: 14,
                  img: require("@/static/pic/zxtr.png"),
                },
              });
              return group;
            } else if (item.type == "Correction Bolus") {
              const group = new G.Group({});
              group.addShape({
                type: "image",
                attrs: {
                  x: 2,
                  y: 16,
                  width:
                    this.chartTime == 0 ? 12 : this.chartTime == 0.5 ? 12 : 14,
                  height:
                    this.chartTime == 0 ? 12 : this.chartTime == 0.5 ? 12 : 14,

                  img: yds,
                },
              });
              group.addShape({
                type: "text",
                attrs: {
                  x: 8,
                  y: 6,
                  text: item.insulin + "U",
                  textAlign: "center",
                  textBaseline: "top",
                  fontSize: 6,
                  fill: "#000",
                },
              });
              return group;
            } else if (item.type == "Carb Correction") {
              const group = new G.Group({});
              group.addShape({
                type: "image",
                attrs: {
                  x: 0,
                  y: 16,
                  width:
                    this.chartTime == 0 ? 12 : this.chartTime == 0.5 ? 12 : 14,
                  height:
                    this.chartTime == 0 ? 12 : this.chartTime == 0.5 ? 12 : 14,
                  img: require("@/static/pic/tstr.png"),
                },
              });
              group.addShape({
                type: "text",
                attrs: {
                  x: 7,
                  y: 28,
                  text: item.carbs + "g",
                  textAlign: "center",
                  textBaseline: "top",
                  fontSize: 6,
                  fill: "#000",
                },
              });
              return group;
            } else if (item.type == "Temporary Override") {
              const group = new G.Group({});
              group.addShape({
                type: "image",
                attrs: {
                  x: 0,
                  y: 10,
                  width:
                    this.chartTime == 0 ? 12 : this.chartTime == 0.5 ? 12 : 14,
                  height:
                    this.chartTime == 0 ? 12 : this.chartTime == 0.5 ? 12 : 14,
                  img: require("@/static/pic/wavens.png"),
                },
              });
              return group;
            } else if (item.type == "Suspend Pump") {
              const group = new G.Group({});
              group.addShape({
                type: "image",
                attrs: {
                  x: 0,
                  y: 10,
                  width:
                    this.chartTime == 0 ? 12 : this.chartTime == 0.5 ? 12 : 14,
                  height:
                    this.chartTime == 0 ? 12 : this.chartTime == 0.5 ? 12 : 14,
                  img: require("@/static/pic/pump.png"),
                },
              });
              return group;
            }
          },
          labelLine: true,
        },
        tooltip: {
          showTitle: false,
          showCrosshairs: false,
          position: "top",
          customContent: (title, data) => {
            let loopType = title
              ? data[0].data.json
                ? JSON.parse(data[0].data.json).type
                : ""
              : "";
            let type = "";
            let eventType = title ? data[0].data.type : "";
            let res = title ? data[0].data : "";
            let parse = title
              ? data[0].data.json
                ? JSON.parse(data[0].data.json)
                : ""
              : "";
            if (title && data[0].data.type === "zx") {
              type = "指尖血";
              return `<div style="padding:4px;">
                      <div style="font-size:14px;font-weight: 700;margin-bottom:4px;">${type}</div>
                      <div style="margin-bottom:4px;"> ${title}</div>
                      <div style="margin-bottom:4px;"> 场景：${res.eventValue}</div>
                      <div style="margin-bottom:4px;">指血指数：${res.way}mmol/L</div>
                    </div>`;
            } else if (title && data[0].data.type === "kfy") {
              type = "口服药";
              return `<div style="padding:4px;">
                      <div style="font-size:14px;font-weight: 700;margin-bottom:4px;">${type}</div>
                      <div style="margin-bottom:4px;"> ${title}</div>
                      <div style="margin-bottom:4px;"> 用药时段：${res.scene}</div>
                      <div style="margin-bottom:4px;"> 药名：${res.way}</div>
                      <div style="margin-bottom:4px;"> 用量：${res.eventValue}</div>
                    </div>`;
            } else if (title && data[0].data.type === "ts") {
              type = "碳水";
              return `<div style="padding:4px;">
                      <div style="font-size:14px;font-weight: 700;margin-bottom:4px;">${type}</div>
                      <div style="margin-bottom:4px;"> ${title}</div>
                      <div style="margin-bottom:4px;"> ${res.eventValue}</div>
                    </div>`;
            } else if (title && data[0].data.type === "yds") {
              type = "胰岛素";
              return `<div style="padding:4px;">
                      <div style="font-size:14px;font-weight: 700;margin-bottom:4px;">${type}</div>
                      <div style="margin-bottom:4px;"> ${title}</div>
                      <div style="margin-bottom:4px;"> 注射时段：${res.scene}</div>
                      <div style="margin-bottom:4px;"> 胰岛素名称：${res.eventValue}</div>
                      <div style="margin-bottom:4px;"> 注射剂量：${res.way}U</div>
                    </div>`;
            } else if (title && data[0].data.type === "yd") {
              type = "运动";
              return `<div style="padding:4px;">
                      <div style="font-size:14px;font-weight: 700;margin-bottom:4px;">${type}</div>
                      <div style="margin-bottom:4px;"> ${title}</div>
                      <div style="margin-bottom:4px;"> 运动时常：${res.scene}h</div>
                      <div style="margin-bottom:4px;"> 消耗：${res.eventValue}kcal</div>
                      <div style="margin-bottom:4px;"> 备注：${res.way}</div>
                    </div>`;
            } else if (title && loopType == 1) {
              type = "关闭闭环";
            } else if (title && loopType == 2) {
              type = "开启闭环";
            } else if (title && loopType == 3) {
              type = "覆盖方波";
              return `<div style="padding:4px;">
                      <div style="font-size:14px;font-weight: 700;margin-bottom:4px;">${type}</div>
                      <div style="margin-bottom:4px;"> ${title}</div>
                      <div style="margin-bottom:4px;"> 持续时间：${parse.minutes}</div>
                      <div style="margin-bottom:4px;"> 原因：${parse.fugainame}</div>
                    </div>`;
            } else if (title && loopType == 4) {
              type = "取消方波";
            } else if (title && loopType == 5) {
              type = "添加碳水";
              return `<div style="padding:4px;">
                      <div style="font-size:14px;font-weight: 700;margin-bottom:4px;">${type}</div>
                      <div style="margin-bottom:4px;"> ${title}</div>
                      <div style="margin-bottom:4px;"> 总量：${parse.carbs}g</div>
                      <div style="margin-bottom:4px;"> 吸收时间：${parse.carbsHouers}h</div>
                    </div>`;
            } else if (title && loopType == 6) {
              type = "大剂量";
              return `<div style="padding:4px;">
                      <div style="font-size:14px;font-weight: 700;margin-bottom:4px;">${type}</div>
                      <div style="margin-bottom:4px;"> ${title}</div>
                      <div style="margin-bottom:4px;"> 剂量：${parse.bolus}U</div>
                    </div>`;
            } else if (title && loopType == 7) {
              type = "临时基础率";
              return `<div style="padding:4px;">
                      <div style="font-size:14px;font-weight: 700;margin-bottom:4px;">${type}</div>
                      <div style="margin-bottom:4px;"> ${title}</div>
                      <div style="margin-bottom:4px;"> 临时基础率：${parse.perHour}U/h</div>
                      <div style="margin-bottom:4px;"> 持续时间：${parse.baseHouers}h</div>
                    </div>`;
            } else if (title && loopType == 8) {
              type = "取消临时";
            } else if (title && eventType == "Correction Bolus") {
              type = "胰岛素";
              return `<div style="padding:4px;">
                      <div style="font-size:14px;font-weight: 700;margin-bottom:4px;">${type}</div>
                      <div style="margin-bottom:4px;"> ${title}</div>
                      <div style="margin-bottom:4px;"> 剂量：${
                        res.insulin
                      }U</div>
                      <div style="margin-bottom:4px;"> 持续时间：${(
                        res.duration * 1
                      ).toFixed(2)}</div>
                      <div style="margin-bottom:4px;"> 名称：${
                        res.insulinType
                      }</div>
                    </div>`;
            } else if (title && eventType == "Carb Correction") {
              type = "碳水";
              return `<div style="padding:4px;">
                      <div style="font-size:14px;font-weight: 700;margin-bottom:4px;">${type}</div>
                      <div style="margin-bottom:4px;"> ${title}</div>
                      <div style="margin-bottom:4px;"> 克数：${
                        res.carbs ? res.carbs + "g" : "无"
                      }</div>
                      <div style="margin-bottom:4px;"> 类型：${
                        res.foodType ? res.foodType : "无"
                      }</div>
                    </div>`;
            } else if (title && eventType == "Temporary Override") {
              type = "覆盖方波";
              return `<div style="padding:4px;">
                      <div style="font-size:14px;font-weight: 700;margin-bottom:4px;">${type}</div>
                      <div style="margin-bottom:4px;"> ${title}</div>
                      <div style="margin-bottom:4px;"> 需求比例：${
                        res.insulinNeedsScaleFactor
                      }</div>
                      <div style="margin-bottom:4px;"> 持续时间：${res.duration.toFixed(
                        2
                      )}分钟</div>
                      <div style="margin-bottom:4px;"> 原因：${res.reason}</div>
                    </div>`;
            } else if (title && eventType == "Suspend Pump") {
              type = "泵";
            } else if (title && eventType == "sugar") {
              type = "血糖";
              return `<div style="padding:4px;">
                      <div style="font-size:14px;font-weight: 700;margin-bottom:4px;">${type}:${data[0].data.v}</div>
                      <div style="margin-bottom:4px;"> ${title}</div>
                    </div>`;
            } else {
              type = "其他";
            }
            return `<div style="padding:4px;">
                      <div style="font-size:14px;font-weight: 700;margin-bottom:4px;">${type}</div>
                      <div> ${title}</div>
                    </div>`;
          },
        },
        xAxis: {
          tickCount: 5,
        },
        yAxis: {
          min: 0,
          max: 20,
        },
        annotations: [
          {
            type: "region",
            start: ["min", 3.9],
            end: ["max", 10],
            style: {
              stroke: "#edf0f2",
              lineDash: [2, 2],
            },
          },

          // {
          //   type: 'regionFilter',
          //   start: ['min', 10],
          //   end: ['max', 20],
          //   color: '#F4664A',
          // },
          {
            type: "text",
            position: ["min", 10],
            content: "10",
            style: {
              fill: "#F4664A",
            },
          },
          // {
          //   type: 'regionFilter',
          //   start: ['min', 0],
          //   end: ['max', 3.9],
          //   color: '#ffaa29',
          // },
          {
            type: "text",
            position: ["min", 3.9],
            content: "3.9",
            style: {
              fill: "#ffaa29",
            },
          },
        ],
      });
      this.scatterPlot.render();

      this.scatterPlot.on("slider:touchstart", (...args) => {
        this.isDown = true;
      });

      this.scatterPlot.on("slider:touchmove", (...args) => {
        if (this.isDown) {
          this.handleMove(args[0]);
        }
      });
    },

    getTempChart() {
      const data = this.tempBasal;
      this.tempPlot = new Line("temp", {
        appendPadding: [0, 15, 0, 2],
        data,
        xField: "create_time",
        yField: "rate",
        stepType: "hv",
        tooltip: true,
        tooltip: {
          customContent: (title, data) => {
            return `<div style="padding:4px;">
              <div style="font-size:14px;font-weight: 700;margin-bottom:4px;">基础率:${
                data[0] ? data[0].value : ""
              }</div>
              </div>`;
          },
        },
        xAxis: {
          visible: false, // x轴隐藏
          tickCount: 5,
        },
        slider: {
          visible: false,
          start: this.sliderStart,
          end: this.sliderEnd,
          height: 0,
          trendCfg: {
            lineStyle: {
              stroke: "#5b8ff9",
              lineWidth: 2,
              lineDash: [4, 5],
              strokeOpacity: 0.7,
              shadowColor: "#5b8ff9",
              shadowBlur: 10,
              shadowOffsetX: 5,
              shadowOffsetY: 5,
              cursor: "pointer",
            },
          },
        },
        yAxis: {
          visible: false, // y轴隐藏
          max: this.maxRate,
          min: 0,
          grid: {
            visible: false, // 栅格隐藏
          },
        },
        lineStyle: {
          lineWidth: 1,
        },
        annotations: [
          // 低于中位数颜色变化
          {
            type: "text",
            position: ["max", this.maxRate],
            content: this.maxRate,
            offsetY: 8,
            style: {
              textBaseline: "bottom",
              fill: "red",
            },
          },
          {
            type: "line",
            start: ["min", this.maxRate],
            end: ["max", this.maxRate],
            style: {
              stroke: "#6294f9",
              lineDash: [2, 2],
            },
          },
        ],
        // area: {
        //   fill: true,
        //   style: {
        //     fillOpacity: 0.55,
        //   },
        // },
      });
      this.tempPlot.render();
    },

    // 切换时间
    getDateArray() {
      this.minTime = this.minTime24;
      this.tickInterval = 4 * 60 * 60 * 1000;
      this.chartData = [];
      const params = {
        id: JSON.parse(localStorage.getItem("appuser")).id,
        end: this.$moment().format("YYYY-MM-DD HH:mm:ss"),
        start: this.$moment()
          .subtract(23, "hours")
          .format("YYYY-MM-DD HH:mm:ss"),
      };
      this.getTreat();
      getSugerbyId(params).then((res) => {
        const length = res.data.data.length;
        if (res.data.data && length) {
          if (length > 1) {
            if (res.data.data[length - 1].v > res.data.data[length - 2].v) {
              this.trend = 1;
            } else if (
              res.data.data[length - 1].v < res.data.data[length - 2].v
            ) {
              this.trend = 2;
            } else {
              this.trend = 0;
            }
            const leftSugar = (
              res.data.data[length - 1].v - res.data.data[length - 2].v
            ).toFixed(1);
            const lastTime = res.data.data[length - 1].create_time;
            this.$emit(
              "getTrend",
              this.trend,
              res.data.data[length - 1].v,
              leftSugar,
              lastTime
            );
          }
          const create_time = res.data.data[length - 1].create_time;
          let params1 = {
            uid: this.$route.query.patient_id
              ? this.$route.query.patient_id
              : this.$route.query.id,
            create_time: create_time,
          };
          loopHistory(params1).then((res) => {
            this.$emit("loopInfo", res);
          });
        }
        this.$nextTick(() => {
          this.scatterPlot.destroy();
          this.tempPlot.destroy();
          this.getChart();
          this.getTempChart();
        });
        this.getAllEvent();
      });
    },
  },
  computed: {
    maxTime() {
      const nextTime = this.$moment()
        .add(1, "hours")
        .format("YYYY-MM-DD HH:00:00");
      return new Date(nextTime).getTime();
    },
    minTime24() {
      const min = this.$moment(
        this.$moment(this.$moment().subtract(23, "hours")).startOf("hour")
      ).format("YYYY-MM-DD HH:00:00");
      return new Date(min).getTime();
    },
  },
  watch: {
    // 每4分钟自动渲染一次chart2，要先清除
    timerNum: {
      handler: function (newVal, oldVal) {
        this.$nextTick(() => {
          this.scatterPlot.destroy();
          this.getDateArray();
          this.flag = true;
        });
      },
    },

    tempBasal: {
      handler: function (newVal, oldVal) {
        if (oldVal) {
          this.$nextTick(() => {
            if (this.tempPlot) {
              this.tempPlot.destroy();
              this.getTempChart();
            }
          });
        }
      },
    },

  },
};
</script>
<style lang="less" scoped>
.title {
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 20px;
}
* {
  list-style: none;
}
.box {
  width: 98.8%;
  margin: 0.6rem;
  border-radius: 1.2rem;
  background: rgb(255, 255, 255);
  box-shadow: 2px 2px 2px 0px rgba(6, 14, 33, 0.16);
  position: relative;
}
.common-title {
  height: 3.4rem;
  font-size: 1.4rem;
  font-weight: 700;
  text-align: start;
  line-height: 3.4rem;
  /* border-bottom: 1px solid #ccc; */
  padding-left: 1rem;
  background: aliceblue;
}
.van-radio-group--horizontal {
  justify-content: center;
}
.unit {
  position: absolute;
  left: 5px;
  margin-top: 5px;
}
.van-radio__label {
  color: #7accff;
}
.content {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0px;
  bottom: 0px;
}
.handleShow {
  position: absolute;
  right: 2rem;
  top: 1rem;
}
.shuiping {
  width: 100vh;
  height: 100vw;
  position: absolute;
  top: 49%;
  left: 48%;
  transform: translate(-50%, -50%) rotate(90deg);
  z-index: 9999;
}
.imgBox > li {
  position: absolute;
  top: 4rem;
}
.imgBox > li img {
  position: absolute;
  left: 0;
  width: 15px;
  height: 15px;
  border-radius: 50%;
}
.imgBox > li > div {
  display: none;
  position: absolute;
  left: -12px;
  top: 17px;
  width: 40px;
  height: 40px;
  background-color: black;
  color: #fff;
  border-radius: 5px;
}
/* 梯形图样式 */
#temp {
  position: absolute;
  top: 5rem;
  z-index: 9999999999;
}
</style>
