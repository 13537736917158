<template>
  <div id="loginView">
    <div style="margin-top: 6rem; color: #333; font-size: 2rem; font-weight: bold; text-align: center">
      手机验证码登录
    </div>
    <!-- <div
      style="
        margin-top: 2rem;
        color: darkgray;
        font-size: 1rem;
        font-weight: 300;
        text-align: center;
      "
    >
      未注册的手机号将自动注册并登录
    </div> -->
    <div style="width: 90%; margin-left: 5%; margin-top: 6rem">
      <div
        style="
          background: #f5f7f7;
          width: 90%;
          margin-left: 5%;
          height: 4rem;
          line-height: 4rem;
          font-size: 1.2rem;
          border-radius: 2rem;
        "
      >
        <el-row>
          <el-col :span="4">
            <div style="font-weight: bold; width: 100%; height: 4rem; text-align: center">+86</div>
          </el-col>
          <el-col :span="20">
            <input
              placeholder="请输入手机号"
              type="text"
              pattern="d*"
              @input="handleInput"
              v-model="phoneNumber"
              style="border: 0 solid #fff; height: 3rem; line-height: 3rem"
            />
          </el-col>
        </el-row>
      </div>

      <div
        style="
          background: #f5f7f7;
          width: 90%;
          margin-left: 5%;
          margin-top: 2rem;
          height: 4rem;
          line-height: 4rem;
          font-size: 1.2rem;
          border-radius: 2rem;
        "
      >
        <el-row>
          <el-col :span="14">
            <div style="text-align: left; margin-left: 4rem; color: #7a7a7a">
              <input
                placeholder="输入验证码"
                type="text"
                maxlength="4"
                style="border: 0 solid #fff; height: 3rem; line-height: 3rem"
                v-model="code"
              />
            </div>
          </el-col>
          <el-col :span="10">
            <div v-if="showGetCode" @click="getCode" style="width: 100%; text-align: center; font-weight: 400">
              发送验证码
            </div>
            <div v-if="!showGetCode" style="width: 100%; text-align: center; font-weight: 400">
              {{ timeOut }}秒后重试
            </div>
          </el-col>
        </el-row>
      </div>

      <div style="margin-top: 2rem">
        <el-button
          :disabled="cantClick"
          style="background: #266eff; width: 80%; height: 48px; border-radius: 2rem"
          type="primary"
          @click="login"
        >
          登录
          <span v-if="this.cantClick">中</span>
        </el-button>
      </div>
      <div @click="goToPasswordLogin" style="margin-top: 3rem; color: #409eff">已有账号密码，使用密码登录</div>
      <!-- <div>
          <h1>{{  msg}}</h1>
          <el-button @click="webtonative">web---native</el-button>
          <el-button @click="nativetoweb">native---web</el-button>
        </div> -->
    </div>

    <!-- 闭环类型 -->
    <van-dialog
      v-model="hospitalDialog"
      class="hospitalDialog"
      title="选择闭环类型"
      showConfirmButton
      confirmButtonText="关闭"
    >
      <div class="dialog-box" @click="hospitalClick('loop')">loop</div>
      <div class="dialog-box" @click="hospitalClick('aaps')">aaps</div>
    </van-dialog>

    <!-- 用户信息修改页面 -->
    <EditDialog v-if="showEditDialog" :registerType="registerType"></EditDialog>
  </div>
</template>
<script>
  import { Toast } from 'vant'
  import Vue from 'vue'
  // eslint-disable-next-line no-unused-vars
  import { getCode, login, register } from '../../api/userServices'
  import { getHospitalByDoctor } from '../../api/relationship'
  import EditDialog from '../mineView/component/editDialog.vue'
  export default {
    name: 'LoginView',
    components: {
      EditDialog,
    },
    data() {
      return {
        msg: '11',
        cantClick: false,
        phoneNumber: '',
        timeOut: 120,
        showGetCode: true,
        hadGetCode: false,
        show: false,
        timer: '',
        code: '',
        hospitalDialog: false,
        hospitalList: [],
        showEditDialog: false,
        registerType: null,
      }
    },

    mounted() {
      const loginStatus = JSON.parse(localStorage.getItem('loginStatus'))
      if (loginStatus) {
        this.$router.push({ path: '/BloodSugar', query: { index: 0 } })
      }
    },

    methods: {
      handleInput(e) {
        let value = e.target.value + ''
        let regSpace = /\s/
        // 如果有空格，去掉空格
        if (regSpace.test(value)) {
          this.phoneNumber = value.replace(/\s+/g, '')
          return
        }
        // 限制输入位数
        if (value.length > 11) {
          this.phoneNumber = value.slice(0, 11)
        }
      },
      webtonative() {
        window.native.webToNative('web to native')
      },
      nativetoweb() {
        this.msg = window.native.nativeToWeb()
      },
      goToPasswordLogin() {
        this.$router.push('/passwordLogin')
      },
      login() {
        // const _this = this
        this.cantClick = true
        setTimeout(() => {
          this.cantClick = false
        }, 3000)
        const params = {
          phone: this.phoneNumber,
          code: this.code,
          type: 1,
        }
        login(params)
          .then((res) => {
            const code = res.data.code
            if (code == 200 || code == 201) {
              const targetHigh = res.data.data.appuser.targetHigh ? res.data.data.appuser.targetHigh : '10'
              const targetLow = res.data.data.appuser.targetLow ? res.data.data.appuser.targetLow : '3.9'
              localStorage.setItem('targetHigh', targetHigh)
              localStorage.setItem('targetLow', targetLow)
              localStorage.setItem('token', res.data.data.token)
              localStorage.setItem('appuser', JSON.stringify(res.data.data.appuser))
              localStorage.setItem('loginStatus', true)
              const message = {
                token: res.data.data.token,
              }
              const androidMessage = JSON.stringify(message)
              try {
                const phoneType = localStorage.getItem('phoneType')
                if (phoneType == 'Android') {
                  window.android.setTokenToIOS(androidMessage)
                } else {
                  window.webkit.messageHandlers.setTokenToIOS.postMessage(message)
                }
              } catch (error) {}
              if (res.data.data.appuser.loopRemote == 'aapsloop') {
                this.hospitalDialog = true
              } else {
                this.$ws.start()
                this.$router.push({
                  path: '/BloodSugar',
                  query: { index: 0 },
                })
                Toast('登录成功！')
              }
            } else {
              Toast(res.data.code || '登录失败')
              // this.$router.push('/register');
            }
          })
          .catch((error) => {
            Toast(res.data.code || '登录失败')
          })
      },
      // 选择闭环类型
      hospitalClick(name) {
        let data = JSON.parse(localStorage.getItem('appuser'))
        const newData = {
          ...data,
          loopRemote: name,
        }
        localStorage.setItem('appuser', JSON.stringify(newData))
        this.$ws.start()
        this.hospitalDialog = false
        this.$router.push({
          path: '/BloodSugar',
          query: { index: 0 },
        })
        Toast('登录成功！')
      },
      getCode() {
        this.waitGetCode()
      },
      waitGetCode() {
        if (!this.phoneNumber || this.phoneNumber.length === 0) {
          Toast('请先输入手机号')
        }
        const regtel = /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/
        if (!regtel.test(this.phoneNumber) || this.phoneNumber.length !== 11) {
          Toast('手机号不正确')
          return
        }
        this.hadGetCode = true
        this.showGetCode = false
        this.timeOut = 120
        getCode(this.phoneNumber)
        const _this = this
        this.timer = setInterval(function () {
          if (_this.timeOut > 1) {
            _this.timeOut = _this.timeOut - 1
          } else {
            _this.closeInterval()
          }
        }, 1000)
      },
      closeInterval() {
        this.showGetCode = true
        clearInterval(this.timer)
      },
    },
  }
</script>
<style scoped lang="less">
  #loginView {
    width: 100%;
    height: 100vh;
    position: fixed;
    z-index: 100;
    top: 0;
    left: 0;
    background: url('../../static/pic/loginBG.jpg') 0% 88%;
  }
  input {
    background-color: transparent;
  }

  ::v-deep .hospitalDialog {
    .van-dialog__header {
      padding-top: 10px;
      font-weight: 700;
    }
  }
  .dialog-box {
    align-items: center;
    background-color: #dbe3f8;
    border-bottom: 3px solid #b0bee4;
    border-radius: 0.375rem;
    color: #6372a5;
    display: flex;
    font-size: 1.125rem;
    font-weight: 600;
    height: 3rem;
    justify-content: center;
    overflow: hidden;
    padding: 0 1rem;
    text-decoration: none;
    transition: 0.2s ease-in-out;
    transition-property: background-color, box-shadow, border;
    width: 70%;
    cursor: pointer;
    margin: 0 auto;
    margin-bottom: 10px;
  }
  .dialog-box:hover {
    background-color: #b0bee4;
    border-color: #98a7d4;
    color: #485584;
    outline: none;
  }
</style>
