const state = {
  socket: null,
  isConnected: false,
  reconnectError: false,
  heartbeatInterval: null,
  heartbeatTimeout: null,
  reconnectTimeout: null,
  pongReceived: false,
  reconnectDelay: 3000, // 重连间隔时间
  heartbeatMsg: "ping", // 心跳消息
  socketId: "",
  message: "",
};

const mutations = {
  SOCKETID_SET(state, id) {
    state.socketId = id;
  },
  SOCKET_ONOPEN(state, event) {
    state.isConnected = true;
  },
  SOCKET_ONCLOSE(state, event) {
    state.isConnected = false;
  },
  SOCKET_ONERROR(state, event) {
    console.error("WebSocket error:", event);
  },
  SOCKET_ONMESSAGE(state, message) {
    const type = JSON.parse(message.data).type;
    console.log("message-来自服务器的消息: ", type);
    if (type == "0") {
      state.pongReceived = true;
    } else if (type == "1") {
      // 这里可以执行一些特定的操作，比如destroy图表，获取新数据等
      state.message = 1;
    }
  },
  SOCKET_RECONNECT(state) {
    state.reconnectAttempts++;
  },
  SOCKET_RECONNECT_ERROR(state) {
    state.reconnectError = true;
  },
  SET_SOCKET(state, socket) {
    state.socket = socket;
  },
  SET_PONG_RECEIVED(state, received) {
    state.pongReceived = received;
  },
  SET_HEARTBEAT_INTERVAL(state, interval) {
    state.heartbeatInterval = interval;
  },
  SET_HEARTBEAT_TIMEOUT(state, timeout) {
    state.heartbeatTimeout = timeout;
  },
  SET_RECONNECT_TIMEOUT(state, timeout) {
    state.reconnectTimeout = timeout;
  },
};

const actions = {
  initWebSocket({ commit, dispatch, state }, url) {
    const socket = new WebSocket(
      `wss://api.cgm.go-sugar.cn/webws/WebSocket?uid=${state.socketId}`
    );
    socket.addEventListener("open", (event) => dispatch("handleOpen", event));
    socket.addEventListener("message", (event) =>
      dispatch("handleMessage", event)
    );
    socket.addEventListener("close", (event) => dispatch("handleClose", event));
    socket.addEventListener("error", (event) => dispatch("handleError", event));
    commit("SET_SOCKET", socket);
  },
  handleOpen({ commit, dispatch }) {
    console.log("open-连接已打开");
    commit("SOCKET_ONOPEN");
    dispatch("startHeartbeat");
  },
  handleMessage({ commit }, event) {
    commit("SOCKET_ONMESSAGE", event);
  },
  handleClose({ commit, dispatch, rootState }, event) {
    console.log("close-连接已关闭", event);
    commit("SOCKET_ONCLOSE");
    dispatch("cleanup");
    if (event.wasClean) {
      return;
    }
    dispatch("reconnect");
  },
  handleError({ commit, dispatch }) {
    console.error("error-连接发生错误");
    commit("SOCKET_ONERROR");
    dispatch("cleanup");
    dispatch("reconnect");
  },
  startHeartbeat({ commit, state, dispatch }) {
    const heartbeatInterval = setInterval(() => {
      if (state.socket.readyState === WebSocket.OPEN) {
        commit("SET_PONG_RECEIVED", false);
        state.socket.send(state.heartbeatMsg);
        console.log("Heartbeat sent: ", state.heartbeatMsg);

        const heartbeatTimeout = setTimeout(() => {
          if (!state.pongReceived) {
            console.log("没收到pong");
            state.socket.close();
          }
        }, 3000);

        commit("SET_HEARTBEAT_TIMEOUT", heartbeatTimeout);
      }
    }, 10000); // 每30秒发一次心跳

    commit("SET_HEARTBEAT_INTERVAL", heartbeatInterval);
  },
  resetHeartbeat({ commit, dispatch }) {
    dispatch("cleanupHeartbeat");
    dispatch("startHeartbeat");
  },
  cleanup({ commit, state }) {
    if (state.heartbeatInterval) {
      clearInterval(state.heartbeatInterval);
    }
    if (state.reconnectTimeout) {
      clearTimeout(state.reconnectTimeout);
    }
    if (state.heartbeatTimeout) {
      clearTimeout(state.heartbeatTimeout);
    }
  },
  reconnect({ commit, dispatch, state }) {
    console.log("我开始重连了。。。");
    const reconnectTimeout = setTimeout(() => {
      dispatch("initWebSocket");
    }, state.reconnectDelay);

    commit("SET_RECONNECT_TIMEOUT", reconnectTimeout);
  },
  cleanupHeartbeat({ commit, state }) {
    if (state.heartbeatInterval) {
      clearInterval(state.heartbeatInterval);
      commit("SET_HEARTBEAT_INTERVAL", null);
    }
    if (state.heartbeatTimeout) {
      clearTimeout(state.heartbeatTimeout);
      commit("SET_HEARTBEAT_TIMEOUT", null);
    }
  },
  closeSocket({ state }) {
    state.socket.close();
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
